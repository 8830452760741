import { useCallback, useState } from "react";
import useWeb3 from "./useWeb3";
import { getdoproundcreatecontract } from "../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../utils/Environment";
const EthPrice = () => {
  const [balance, setBalance] = useState(null);
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const tokenAddress = Environment.DOPRoundSingleContract;
  const contract = getdoproundcreatecontract(tokenAddress, web3);

  const ethPrice = useCallback(async () => {
    try {
      const buy = await contract.methods
        .getLatestPrice("0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE")
        .call();
      return buy[0] / 10 ** 8;
    } catch (error) {
      throw error;
    }
  }, [contract, account, web3]);
  return { ethPrice: ethPrice };
};
export default EthPrice;
