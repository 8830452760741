import React, { useEffect, useState } from "react";
import "./allagents.scss";
import DataTable from "./DataTable/DataTable";
import ViewDetail from "./ViewDetail";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { Api_Url } from "../../../utils/ApiUrl";
import { useHistory } from "react-router-dom";
import Web3 from "web3";
import { toast } from "react-toastify";

const AllAgents = ({
  user,
  setindexwait,
  GetMyData,
  routes,
  setRoutes,
  tomiPrice,
  usdPrice,
  btcPrice,
}) => {
  const [loader, setLoader] = useState(false);
  const [leadersLoader, setLeadersLoader] = useState(false);
  const [blockUnblock, setBlockUnblock] = useState(false);
  const [codeBlockFilter, setCodeBlockFilter] = useState(true);
  const [deletedFilter, setDeletedFilter] = useState(false);

  // const userObj = JSON.parse(localStorage.getItem("user"));

  const [show, setShow] = useState(false);
  const [agents, setAgents] = useState([]);
  const [name, setName] = useState("");
  const [role, setRole] = useState(null);
  const [addr, setAddr] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [offset, setOffset] = useState(1);
  const [count, setCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const [limit, setLimit] = useState(1);
  const [pages, setPages] = useState([]);
  const [page, setPage] = useState(0);
  const [search0, setSearch0] = useState("");
  const [userDel, setUserDel] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [leaderNames, setLeaderNames] = useState([]);
  const [leaderNames2, setLeaderNames2] = useState([]);
  const [leaderFilter, setLeaderFilter] = useState("");
  const [activeFilter, setActiveFilter] = useState(false);
  const [inActiveFilter, setInActiveFilter] = useState(false);
  const [roleTypeFilter, setRoleTypeFilter] = useState("");

  const [searchQuery, setSearchQuery] = useState("");
  const [myTree, setMyTree] = useState(false);

  const [orderField, setOrderField] = useState("createdAt");
  const [orderDirection, setOrderDirection] = useState("-1");
  // const [tomiPrice, setTomiPrice] = useState("");

  const history = useHistory();

  const handleClose = () => setShow(false);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => {
    setName("");
    setAddr("");
    setEmail("");
    setRole(null);
    setShow1(false);
  };

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);

  function isValidEmail(email) {
    var pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return pattern.test(email);
  }

  function isAddressValid(address) {
    return Web3.utils.isAddress(address);
  }

  useEffect(() => {
    getLeaderNames();
    // getTomiPrice();
  }, []);

  useEffect(() => {
    if (user) {
      GetAgents();
    }
  }, [
    user,
    myTree,
    activeFilter,
    inActiveFilter,
    leaderFilter,
    roleTypeFilter,
    searchQuery,
    orderField,
    orderDirection,
    codeBlockFilter,
    deletedFilter,
    page,
  ]);

  // const getTomiPrice = async () => {
  //   try {
  //     const response = await axios.get(
  //       `https://api.coingecko.com/api/v3/simple/price`,
  //       {
  //         params: {
  //           ids: "tomiNet",
  //           vs_currencies: "usd",
  //         },
  //       }
  //     );

  //     const data = response?.data;
  //     const priceInUSD = data["tominet"]?.usd;

  //     setTomiPrice(priceInUSD);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //     // throw error;
  //   }
  // };

  const handlePageChange = (e) => {
    const selectedPage = e.selected;

    setPage(selectedPage);
  };

  // useEffect(() => {
  //   if (leaderFilter !== "") {
  //     GetAgents();
  //   }
  // }, [leaderFilter]);

  const getLeaderNames = () => {
    const val = localStorage.getItem("accessToken");

    var config = {
      method: "get",
      url: `${Api_Url}/users/leaders-names?limit=${2000}`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    axios(config)
      .then(function (response) {
        setLeaderNames(response?.data?.data);
        setLeaderNames2(response?.data?.data);
      })
      .catch(function (error) {
        if (error?.response?.status == 401) {
          // localStorage.removeItem("accessToken");
          // history.push("/");
        }
      });
  };

  const GetAgents = () => {
    setAgents([]);
    setLeadersLoader(true);
    const val = localStorage.getItem("accessToken");

    var config = {
      method: "get",
      url: `${Api_Url}/users?limit=${10}&offset=${page + 1}`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    let dynamicQuery = config.url;

    if (leaderFilter) {
      dynamicQuery += `&name=${encodeURIComponent(leaderFilter)}`;
    }

    if (roleTypeFilter) {
      dynamicQuery += `&leaderType=${encodeURIComponent(roleTypeFilter)}`;
    }

    if (activeFilter) {
      dynamicQuery += `&active=${activeFilter}`;
    }
    if (inActiveFilter) {
      dynamicQuery += `&inActive=${inActiveFilter}`;
    }

    // if (!codeBlockFilter) {
    //   dynamicQuery += `&accessCodeEnabled=${codeBlockFilter}`;
    // }
    // if (deletedFilter) {
    //   dynamicQuery += `&isDeleted=${deletedFilter}`;
    // }

    if (searchQuery) {
      dynamicQuery += `&search=${encodeURIComponent(searchQuery)}`;
    }
    if (orderField) {
      dynamicQuery += `&orderField=${orderField}&orderDirection=${orderDirection}`;
    }
    if (user?.role == "god_agent") {
      dynamicQuery += `&myTree=${myTree}`;
    }
    config.url = dynamicQuery;

    axios(config)
      .then(function (response) {
        const resData = response?.data?.data;
        setCount(response.data.data.count);
        setAgents(resData?.agents);
        let arr = Array.from(Array(parseInt(response.data.data.pages)).keys());
        setPageCount(response.data.data.pages);
        setPages(arr);

        setLeadersLoader(false);
      })
      .catch(function (error) {
        if (error?.response?.status == 401) {
          // localStorage.removeItem("accessToken");
          // history.push("/");
        }
        setLeadersLoader(false);
      });
  };

  const getPrevData = (off) => {
    setAgents([]);

    setLeadersLoader(true);

    let offset = parseInt(off) - 1;
    const val = localStorage.getItem("accessToken");
    if (offset > 0) {
      // setLoader(true);
      var config = null;
      config = {
        method: "get",
        url: `${Api_Url}/users?offset=${offset}&limit=10&orderField=createdAt&orderDirection=-1`,
        headers: {
          Authorization: "Bearer " + val,
          "Content-Type": "application/json",
        },
      };

      axios(config)
        .then(function (response) {
          const resData = response?.data?.data;
          let arr = Array.from(
            Array(parseInt(response.data.data.pages)).keys()
          );
          setPages(arr);
          setAgents(resData?.agents);
          if (currentPage - 1 >= 0) {
            setCurrentPage(currentPage - 1);
          }
          if (off >= 0) {
            if (offset - 1 == 0) {
              setLimit(1);
            } else {
              setLimit((offset - 1) * 5);
            }
          }
          setLeadersLoader(false);
        })
        .catch(function (error) {
          console.log(error);
          setLeadersLoader(false);
        });
      // setLoader(false);
    }
  };

  const getNextData = (off) => {
    setAgents([]);

    setLeadersLoader(true);

    let offset = parseInt(off) + 1;
    const val = localStorage.getItem("accessToken");
    if (pages.length > off) {
      if (off < agents.length) {
        var config = null;
        config = {
          method: "get",
          url: `${Api_Url}/users?offset=${offset}&limit=10&orderField=createdAt&orderDirection=-1`,
          headers: {
            Authorization: "Bearer " + val,
            "Content-Type": "application/json",
          },
        };

        axios(config)
          .then(function (response) {
            const resData = response?.data?.data;
            let arr = Array.from(
              Array(parseInt(response.data.data.pages)).keys()
            );
            setPages(arr);
            setAgents(resData?.agents);
            if (off <= agents.length) {
              setCurrentPage(offset);
              setLimit(off * 5);
            }
            setLeadersLoader(false);
          })
          .catch(function (error) {
            setLeadersLoader(false);

            console.log(error);
          });
      }
    }
  };

  const AddAgent = () => {
    const val = localStorage.getItem("accessToken");
    let url = null;
    if (user?.role === "admin") {
      url = `${Api_Url}/users/god-agents`;
    } else if (role === "mega_agent") {
      url = `${Api_Url}/users/mega-agents`;
    } else if (role === "super_agent") {
      url = `${Api_Url}/users/super-agents`;
    } else if (role === "agent") {
      url = `${Api_Url}/users/agents`;
    } else if (role === "mini_agent") {
      url = `${Api_Url}/users/mini-agents`;
    }
    if (name?.length > 2) {
      if (isAddressValid(addr)) {
        if (isValidEmail(email)) {
          setLoader(true);
          var config = {
            method: "post",
            url: url,
            data: {
              name: name,
              walletAddress: addr,
              email: email,
              name: name,
            },
            headers: {
              Authorization: "Bearer " + val,
            },
          };

          axios(config)
            .then(function (response) {
              setLoader(false);
              setName("");
              setAddr("");
              setEmail("");
              setRole(null);
              GetMyData();
              handleClose1();
              setShow2(true);
              GetAgents();
            })
            .catch(function (error) {
              setLoader(false);
              toast.error(error?.response?.data?.message);
              if (error?.response?.status == 401) {
                // localStorage.removeItem("accessToken");
                // history.push("/");
              }
            });
        } else {
          setError("Incorrect Email!");
        }
      } else {
        setError("Incorrect wallet address!");
      }
    } else {
      setError("Name must be of length 3!");
    }
  };

  function convertToTitleCase(input) {
    // console.log("cccc");
    if (input) {
      let words = input
        .split("_")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        );

      if (words[0] === "mini" || words[0] === "Mini") {
        return "Ambassador";
      }
      if (
        words[words.length - 1].toLowerCase() === "agent" &&
        (words[0] != "mini" || words[0] != "Mini")
      ) {
        words[words.length - 1] = "Leader";
      }
      if (words[0] === "super" || words[0] === "Super") {
        return "Super";
      } else if (words[0] !== "agent") {
        return words.join(" ");
      } else {
        return "Leader";
      }
    } else {
      return "";
    }
  }

  const DeleteUser = () => {
    const val = localStorage.getItem("accessToken");
    var config = {
      method: "patch",
      url: `${Api_Url}/users/${userDel?._id}/toggle-leaders`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    axios(config)
      .then(function (response) {
        GetAgents();
        setShow(false);
        setBlockUnblock(!blockUnblock);
        // setRoutes(false);
        // setLoader(false);
      })
      .catch(function (error) {
        if (error?.response?.status == 401) {
          // localStorage.removeItem("accessToken");
          // history.push("/");
        }
        toast.error(error?.message);
      });
  };

  const functiontogetback = () => {
    localStorage.setItem("indexvalue", 0);
    setindexwait(0);
  };

  const SearchByName = (e) => {
    if (e) {
      e?.preventDefault();
    }
    if (search0?.length == 0) {
      setLeaderNames(leaderNames2);
    } else if (search0?.length > 1) {
      const results = leaderNames.filter((item) =>
        item.name?.toLowerCase().includes(search0?.toLowerCase())
      );
      setLeaderNames(results);
    }
  };

  const GetSearch = (searchKey) => {
    setSearch0(searchKey);
    if (searchKey === "") {
      setLeaderFilter("");
      setLeaderNames(leaderNames2);
    } else if (searchKey?.length > 1) {
      const results = leaderNames2.filter((item) =>
        item.name?.toLowerCase().includes(searchKey?.toLowerCase())
      );
      setLeaderNames(results);
      // SearchByName(searchKey);
    }
  };

  return (
    <>
      {routes ? (
        <>
          {routes ? (
            <>
              <ViewDetail
                user={user}
                userDel={userDel}
                setRoutes={setRoutes}
                setUserDel={setUserDel}
                setShow={setShow}
                setBlockUnblock={setBlockUnblock}
                blockUnblock={blockUnblock}
                tomiPrice={tomiPrice}
                usdPrice={usdPrice}
                btcPrice={btcPrice}
              />
            </>
          ) : (
            <section className="allagents">
              <div className="custom-container">
                <button
                  onClick={() => functiontogetback()}
                  class="btn-back guvxgyvxvtxx"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                  >
                    <path
                      d="M8.37376 16.4675C8.20751 16.4675 8.04126 16.4062 7.91001 16.275L2.59876 10.9637C2.34501 10.71 2.34501 10.29 2.59876 10.0362L7.91001 4.72498C8.16376 4.47123 8.58376 4.47123 8.83751 4.72498C9.09126 4.97873 9.09126 5.39873 8.83751 5.65248L3.99001 10.5L8.83751 15.3475C9.09126 15.6012 9.09126 16.0212 8.83751 16.275C8.71501 16.4062 8.54001 16.4675 8.37376 16.4675Z"
                      fill="white"
                    ></path>
                    <path
                      d="M17.9375 11.1562H3.21124C2.85249 11.1562 2.55499 10.8587 2.55499 10.5C2.55499 10.1413 2.85249 9.84375 3.21124 9.84375H17.9375C18.2962 9.84375 18.5937 10.1413 18.5937 10.5C18.5937 10.8587 18.2962 11.1562 17.9375 11.1562Z"
                      fill="white"
                    ></path>
                  </svg>{" "}
                  Back
                </button>
                {/* <div className="main-heading">
                  {user?.accessCode ? (
                    <h6>All Leaders ({user?.accessCode})</h6>
                  ) : (
                    <h6>All Leaders</h6>
                  )}
                  {user?.role !== "agent" && (
                    <button onClick={() => setShow1(true)} className="mega-btn">
                      Add Leader
                    </button>
                  )}
                </div> */}
                <div className="main-heading">
                  {user?.accessCode ? (
                    <h6>All Leaders ({user?.accessCode})</h6>
                  ) : (
                    <h6>All Leaders</h6>
                  )}
                  <div className="rightside-content">
                    <div className="custom-option-field">
                      <input
                        type="search"
                        placeholder="Search by wallet address..."
                        value={searchQuery}
                        onChange={(e) => {
                          setSearchQuery(e.target.value);
                        }}
                      />
                      <img
                        src="\assets\search-icon.svg"
                        alt="img"
                        className="img-fluid search-icon"
                      />
                    </div>
                    {user?.role == "mini_agent" ? (
                      ""
                    ) : (
                      <div class="dropdown">
                        <button
                          class="dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <div
                            onClick={() => {
                              setRoleTypeFilter(roleTypeFilter);
                            }}
                          >
                            {roleTypeFilter == "god_agent"
                              ? "God Leader "
                              : roleTypeFilter == "mega_agent"
                              ? "Mega Leader "
                              : roleTypeFilter == "super_agent"
                              ? "Super Leader "
                              : roleTypeFilter == "agent"
                              ? "Leader "
                              : roleTypeFilter == "mini_agent"
                              ? "Ambassador "
                              : "Role "}
                          </div>
                          <img
                            src="\assets\arrow-down.svg"
                            alt="img"
                            className="img-fluid"
                          />
                        </button>
                        {user?.role == "admin" ? (
                          <ul class="dropdown-menu">
                            <li>
                              <a
                                onClick={() => setRoleTypeFilter("")}
                                class="dropdown-item"
                                href="#"
                              >
                                All Leaders
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("god_agent");
                                }}
                              >
                                God Leader
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("mega_agent");
                                }}
                              >
                                Mega Leader
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("super_agent");
                                }}
                              >
                                Super Leader
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("agent");
                                }}
                              >
                                Leader
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("mini_agent");
                                }}
                              >
                                Ambassador
                              </a>
                            </li>
                          </ul>
                        ) : user?.role == "god_agent" ? (
                          <ul class="dropdown-menu">
                            <li>
                              <a
                                onClick={() => setRoleTypeFilter("")}
                                class="dropdown-item"
                                href="#"
                              >
                                All Leaders
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("mega_agent");
                                }}
                              >
                                Mega Leader
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("super_agent");
                                }}
                              >
                                Super Leader
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("agent");
                                }}
                              >
                                Leader
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("mini_agent");
                                }}
                              >
                                Ambassador
                              </a>
                            </li>
                          </ul>
                        ) : user?.role == "mega_agent" ? (
                          <ul class="dropdown-menu">
                            <li>
                              <a
                                onClick={() => setRoleTypeFilter("")}
                                class="dropdown-item"
                                href="#"
                              >
                                All Leaders
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("super_agent");
                                }}
                              >
                                Super Leader
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("agent");
                                }}
                              >
                                Leader
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("mini_agent");
                                }}
                              >
                                Ambassador
                              </a>
                            </li>
                          </ul>
                        ) : user?.role == "super_agent" ? (
                          <ul class="dropdown-menu">
                            <li>
                              <a
                                onClick={() => setRoleTypeFilter("")}
                                class="dropdown-item"
                                href="#"
                              >
                                All Leaders
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("agent");
                                }}
                              >
                                Leader
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("mini_agent");
                                }}
                              >
                                Ambassador
                              </a>
                            </li>
                          </ul>
                        ) : user?.role == "agent" ? (
                          <ul class="dropdown-menu">
                            <li>
                              <a
                                onClick={() => setRoleTypeFilter("")}
                                class="dropdown-item"
                                href="#"
                              >
                                All Leaders
                              </a>
                            </li>

                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("mini_agent");
                                }}
                              >
                                Ambassador
                              </a>
                            </li>
                          </ul>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                    {/* <div class="dropdown">
                      <button
                        class="dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <div
                          onClick={() => {
                            setRoleTypeFilter("");
                          }}
                        >
                          {roleTypeFilter == "god_agent"
                            ? "God Leader "
                            : roleTypeFilter == "mega_agent"
                              ? "Mega Leader "
                              : roleTypeFilter == "Super_agent"
                                ? "Super Leader "
                                : roleTypeFilter == "agent"
                                  ? "Leader "
                                  : "Role "}

                          <img
                            src="\assets\arrow-down.svg"
                            alt="img"
                            className="img-fluid"
                          />
                        </div>
                      </button>
                      <ul class="dropdown-menu">
                        <li>
                          <a
                            class="dropdown-item"
                            href="#"
                            onClick={() => {
                              setRoleTypeFilter("god_agent");
                            }}
                          >
                            God Leader
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="#"
                            onClick={() => {
                              setRoleTypeFilter("mega_agent");
                            }}
                          >
                            Mega Leader
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="#"
                            onClick={() => {
                              setRoleTypeFilter("super_agent");
                            }}
                          >
                            Super Leader
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="#"
                            onClick={() => {
                              setRoleTypeFilter("agent");
                            }}
                          >
                            Leader
                          </a>
                        </li>
                      </ul>
                    </div> */}
                    <div class="dropdown">
                      <button
                        class="dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <div
                          onClick={() => {
                            // setLeaderFilter("");
                          }}
                        >
                          {leaderFilter ? leaderFilter + " " : "Type "}

                          <img
                            src="\assets\arrow-down.svg"
                            alt="img"
                            className="img-fluid"
                          />
                        </div>
                      </button>
                      <ul class="dropdown-menu">
                        {leaderNames?.length > 0
                          ? leaderNames?.map((item) => {
                              return (
                                <>
                                  <li>
                                    <a
                                      class="dropdown-item"
                                      href="#"
                                      onClick={() => {
                                        setLeaderFilter(item?.name);
                                      }}
                                    >
                                      {item?.name}
                                    </a>
                                  </li>
                                </>
                              );
                            })
                          : null}
                      </ul>
                    </div>
                    {/* <button
                      className="reset-btn-global"
                      onClick={() => {
                        setRoleTypeFilter("");
                        setLeaderFilter("");
                        setSearchQuery("");
                      }}
                    >
                      Reset
                    </button> */}
                  </div>
                </div>
                {agents?.length > 0 ? (
                  <DataTable
                    btcPrice={btcPrice}
                    usdPrice={usdPrice}
                    tomiPrice={tomiPrice}
                    setBlockUnblock={setBlockUnblock}
                    blockUnblock={blockUnblock}
                    setUserDel={setUserDel}
                    getNextData={getNextData}
                    currentPage={currentPage}
                    GetAllRounds={GetAgents}
                    pages={pages}
                    getPrevData={getPrevData}
                    GetAgents={GetAgents}
                    page={page}
                    pageCount={pageCount}
                    offset={offset}
                    setOffset={setOffset}
                    agents={agents}
                    setAgents={setAgents}
                    setShow={setShow}
                    setRoutes={setRoutes}
                    setOrderDirection={setOrderDirection}
                    setOrderField={setOrderField}
                    handlePageChange={handlePageChange}
                    pagination={true}
                  />
                ) : leadersLoader ? (
                  <p
                    className=""
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      marginTop: 100,
                    }}
                  >
                    Loading...
                  </p>
                ) : agents?.length == 0 ? (
                  <p
                    className=""
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      marginTop: 100,
                    }}
                  >
                    No data found!
                  </p>
                ) : null}
              </div>
            </section>
          )}
          <Modal
            className="delete-modal global-modal-scss"
            show={show}
            onHide={handleClose}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Block/Unlock{blockUnblock ? "Unblock" : "Block"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="delete-modal-div">
                <img
                  src="\assets\delete-modal-icon.svg"
                  alt="img"
                  className="img-fluid"
                />
                <h6>
                  Are you sure you want to
                  {blockUnblock ? "Unblock" : "Block"} this?
                </h6>

                {/* <p>
                  This will delete it permanently. You cannot undo this action.
                </p> */}
              </div>
              <div className="twice-btn">
                <button onClick={() => handleClose()} className="btn-cancel">
                  Cancel
                </button>
                <button className="btn-del" onClick={() => DeleteUser()}>
                  {blockUnblock ? "Unblock" : "Block"}
                </button>
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            className="add-mega-modal global-modal-scss"
            show={show1}
            onHide={handleClose1}
            centered
          >
            <Modal.Header closeButton>
              {user?.role === "admin" ? (
                <Modal.Title>Add God Leader</Modal.Title>
              ) : (
                <Modal.Title>Add Leader</Modal.Title>
              )}
              {/* {user?.role === "admin" && <Modal.Title>Add Mega Agents</Modal.Title>}
                    {user?.role === "admin" && <Modal.Title>Add Mega Agents</Modal.Title>} */}
            </Modal.Header>
            <Modal.Body>
              <div className="add-mega-div">
                <div className="option-field">
                  <div className="material-textfield">
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Enter Leader’s Name"
                    />
                    <label>Leader’s Name</label>
                  </div>
                </div>
                <div className="option-field">
                  <div className="material-textfield">
                    <input
                      type="text"
                      value={addr}
                      onChange={(e) => setAddr(e.target.value)}
                      placeholder="Enter Leader’s Wallet Address"
                    />
                    <label>Leader’s Wallet Address</label>
                  </div>
                </div>
                <div className="option-field">
                  <div className="material-textfield">
                    <input
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      type="text"
                      placeholder="Enter Leader’s Email Address"
                    />
                    <label>Leader’s Email Address</label>
                  </div>
                </div>
                {user?.role !== "admin" && (
                  <div className="option-field">
                    <div class="dropdown">
                      <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {role === null
                          ? "Type of Leader"
                          : convertToTitleCase(role)}
                      </button>
                      {user?.role === "god_agent" && (
                        <ul class="dropdown-menu">
                          <li onClick={() => setRole("mega_agent")}>
                            <a class="dropdown-item" href="#">
                              Mega Leader
                            </a>
                          </li>
                          <li onClick={() => setRole("super_agent")}>
                            <a class="dropdown-item" href="#">
                              Super Leader
                            </a>
                          </li>
                          <li onClick={() => setRole("agent")}>
                            <a class="dropdown-item" href="#">
                              Leader
                            </a>
                          </li>
                          <li onClick={() => setRole("mini_agent")}>
                            <a class="dropdown-item" href="#">
                              Ambassador
                            </a>
                          </li>
                        </ul>
                      )}
                      {user?.role === "mega_agent" && (
                        <ul class="dropdown-menu">
                          <li onClick={() => setRole("super_agent")}>
                            <a class="dropdown-item" href="#">
                              Super Leader
                            </a>
                          </li>
                          <li onClick={() => setRole("agent")}>
                            <a class="dropdown-item" href="#">
                              Leader
                            </a>
                          </li>
                          <li onClick={() => setRole("mini_agent")}>
                            <a class="dropdown-item" href="#">
                              Ambassador
                            </a>
                          </li>
                        </ul>
                      )}
                      {user?.role === "super_agent" && (
                        <ul class="dropdown-menu">
                          <li onClick={() => setRole("agent")}>
                            <a class="dropdown-item" href="#">
                              Leader
                            </a>
                          </li>
                          <li onClick={() => setRole("mini_agent")}>
                            <a class="dropdown-item" href="#">
                              Ambassador
                            </a>
                          </li>
                        </ul>
                      )}
                      {user?.role === "agent" && (
                        <ul class="dropdown-menu">
                          <li onClick={() => setRole("mini_agent")}>
                            <a class="dropdown-item" href="#">
                              Ambassador
                            </a>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <p
                style={{
                  color: "red",
                  textAlign: "center",
                  marginTop: -15,
                  marginBottom: 15,
                }}
              >
                {error}
              </p>
              <div className="twice-btn">
                <button
                  onClick={() => {
                    handleClose1();
                    setError("");
                  }}
                  className="btn-cancel"
                >
                  Back
                </button>
                <button
                  disabled={loader}
                  className="btn-add"
                  onClick={() => AddAgent()}
                >
                  {loader ? "Adding..." : "Add"}
                </button>
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            className="add-mega-modal global-modal-scss"
            show={show2}
            onHide={handleClose2}
            centered
          >
            <Modal.Header closeButton>
              {user?.role === "admin" ? (
                <Modal.Title>Add Mega Leaders</Modal.Title>
              ) : (
                <Modal.Title>Add Leaders</Modal.Title>
              )}
            </Modal.Header>
            <Modal.Body>
              <div className="add-mega-div">
                {user?.role === "admin" ? (
                  <h6>God Leader has been added</h6>
                ) : (
                  <h6>{convertToTitleCase(role)} has been added</h6>
                )}
              </div>
              <div className="twice-btn">
                <button
                  onClick={() => handleClose2()}
                  className="btn-add"
                  style={{ flex: "auto" }}
                >
                  Okay
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </>
      ) : (
        <section className="allagents">
          <div className="custom-container">
            <button
              class="btn-back guvxgyvxvtxx"
              onClick={() => functiontogetback()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
              >
                <path
                  d="M8.37376 16.4675C8.20751 16.4675 8.04126 16.4062 7.91001 16.275L2.59876 10.9637C2.34501 10.71 2.34501 10.29 2.59876 10.0362L7.91001 4.72498C8.16376 4.47123 8.58376 4.47123 8.83751 4.72498C9.09126 4.97873 9.09126 5.39873 8.83751 5.65248L3.99001 10.5L8.83751 15.3475C9.09126 15.6012 9.09126 16.0212 8.83751 16.275C8.71501 16.4062 8.54001 16.4675 8.37376 16.4675Z"
                  fill="white"
                ></path>
                <path
                  d="M17.9375 11.1562H3.21124C2.85249 11.1562 2.55499 10.8587 2.55499 10.5C2.55499 10.1413 2.85249 9.84375 3.21124 9.84375H17.9375C18.2962 9.84375 18.5937 10.1413 18.5937 10.5C18.5937 10.8587 18.2962 11.1562 17.9375 11.1562Z"
                  fill="white"
                ></path>
              </svg>{" "}
              Back
            </button>
            {/* <div className="main-heading">
              {user?.accessCode ? (
                <h6>All Leaders ({user?.accessCode})</h6>
              ) : (
                <h6>All Leaders</h6>
              )}
              {user?.role !== "agent" && (
                <button onClick={() => setShow1(true)} className="mega-btn">
                  Add Leader
                </button>
              )}
            </div> */}
            <div className="main-heading">
              {user?.accessCode ? (
                <h6>All Leaders ({user?.accessCode})</h6>
              ) : (
                <h6>All Leaders</h6>
              )}
              <div className="multi-items">
                {user?.role == "god_agent" &&
                user?.specialAccess?.length > 0 ? (
                  <div className="set-twice-text">
                    <div className="parent-toggle-switch-custom">
                      <div class="custom-toggle">
                        <input
                          type="checkbox"
                          class="checkbox"
                          id="checkbox"
                          checked={myTree}
                          onChange={(e) => {
                            setMyTree(e.target.checked);
                          }}
                        />
                        <label class="switch" for="checkbox">
                          <span class="slider"></span>
                        </label>
                      </div>
                    </div>
                    <h6>Show data from my tree only</h6>
                  </div>
                ) : null}

                <div className="rightside-content">
                  <div className="custom-option-field">
                    <input
                      type="search"
                      placeholder="Search..."
                      value={searchQuery}
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                      }}
                    />

                    <img
                      src="\assets\search-icon.svg"
                      alt="img"
                      className="img-fluid search-icon"
                    />
                  </div>
                  {/* <div class="dropdown">
                  <button
                    class="dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <div
                      onClick={() => {
                        setRoleTypeFilter("");
                      }}
                    >
                      {roleTypeFilter == "god_agent"
                        ? "God Leader "
                        : roleTypeFilter == "mega_agent"
                          ? "Mega Leader "
                          : roleTypeFilter == "Super_agent"
                            ? "Super Leader "
                            : roleTypeFilter == "agent"
                              ? "Leader "
                              : "Role "}

                      <img
                        src="\assets\arrow-down.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </div>
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <a
                        class="dropdown-item"
                        href="#"
                        onClick={() => {
                          setRoleTypeFilter("god_agent");
                        }}
                      >
                        God Leader
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        href="#"
                        onClick={() => {
                          setRoleTypeFilter("mega_agent");
                        }}
                      >
                        Mega Leader
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        href="#"
                        onClick={() => {
                          setRoleTypeFilter("super_agent");
                        }}
                      >
                        Super Leader
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        href="#"
                        onClick={() => {
                          setRoleTypeFilter("agent");
                        }}
                      >
                        Leader
                      </a>
                    </li>
                  </ul>
                </div> */}
                  {/* <div class="dropdown">
                    <button
                      class="dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <div>
                        All Leaders
                        <img
                          src="\assets\arrow-down.svg"
                          alt="img"
                          className="img-fluid ms-2"
                        />
                      </div>
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          class="dropdown-item"
                          href="#"
                          onClick={() => {
                            setCodeBlockFilter(true);
                            setDeletedFilter(false);
                          }}
                        >
                          All Leaders
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item"
                          href="#"
                          onClick={() => {
                            setCodeBlockFilter(false);
                            setDeletedFilter(false);
                          }}
                        >
                          Code Blocked
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item"
                          href="#"
                          onClick={() => {
                            setDeletedFilter(true);
                            setCodeBlockFilter(true);
                          }}
                        >
                          Soft Deleted
                        </a>
                      </li>
                    </ul>
                  </div> */}
                  {user?.role == "mini_agent" ? (
                    ""
                  ) : (
                    <>
                      {user?.role !== "super_agent" && (
                        <div class="dropdown">
                          <button
                            class="dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <div
                              onClick={() => {
                                setRoleTypeFilter(roleTypeFilter);
                              }}
                              className="se-custom-flex-gap"
                            >
                              {roleTypeFilter == "god_agent"
                                ? "God Leader "
                                : roleTypeFilter == "mega_agent"
                                ? "Mega Leader "
                                : roleTypeFilter == "super_agent"
                                ? "Super Leader "
                                : roleTypeFilter == "agent"
                                ? "Leader "
                                : roleTypeFilter == "mini_agent"
                                ? "Ambassador "
                                : "By Role"}
                              <img
                                src="\assets\arrow-down.svg"
                                alt="img"
                                className="img-fluid"
                              />
                            </div>
                          </button>
                          {user?.role == "admin" ? (
                            <ul class="dropdown-menu">
                              <li>
                                <a
                                  onClick={() => setRoleTypeFilter("")}
                                  class="dropdown-item"
                                  href="#"
                                >
                                  All Leaders
                                </a>
                              </li>
                              <li>
                                <a
                                  class="dropdown-item"
                                  href="#"
                                  onClick={() => {
                                    setRoleTypeFilter("god_agent");
                                  }}
                                >
                                  God Leader
                                </a>
                              </li>
                              <li>
                                <a
                                  class="dropdown-item"
                                  href="#"
                                  onClick={() => {
                                    setRoleTypeFilter("mega_agent");
                                  }}
                                >
                                  Mega Leader
                                </a>
                              </li>
                              <li>
                                <a
                                  class="dropdown-item"
                                  href="#"
                                  onClick={() => {
                                    setRoleTypeFilter("super_agent");
                                  }}
                                >
                                  Super Leader
                                </a>
                              </li>
                              <li>
                                <a
                                  class="dropdown-item"
                                  href="#"
                                  onClick={() => {
                                    setRoleTypeFilter("agent");
                                  }}
                                >
                                  Leader
                                </a>
                              </li>
                              <li>
                                <a
                                  class="dropdown-item"
                                  href="#"
                                  onClick={() => {
                                    setRoleTypeFilter("mini_agent");
                                  }}
                                >
                                  Ambassador
                                </a>
                              </li>
                            </ul>
                          ) : user?.role == "god_agent" ? (
                            <ul class="dropdown-menu">
                              <li>
                                <a
                                  onClick={() => setRoleTypeFilter("")}
                                  class="dropdown-item"
                                  href="#"
                                >
                                  All Leaders
                                </a>
                              </li>
                              {user?.specialAccess &&
                              user?.specialAccess?.length > 0 ? (
                                <li>
                                  <a
                                    class="dropdown-item"
                                    href="#"
                                    onClick={() => {
                                      setRoleTypeFilter("god_agent");
                                    }}
                                  >
                                    God Leader
                                  </a>
                                </li>
                              ) : null}

                              <li>
                                <a
                                  class="dropdown-item"
                                  href="#"
                                  onClick={() => {
                                    setRoleTypeFilter("mega_agent");
                                  }}
                                >
                                  Mega Leader
                                </a>
                              </li>
                              <li>
                                <a
                                  class="dropdown-item"
                                  href="#"
                                  onClick={() => {
                                    setRoleTypeFilter("super_agent");
                                  }}
                                >
                                  Super Leader
                                </a>
                              </li>
                              <li>
                                <a
                                  class="dropdown-item"
                                  href="#"
                                  onClick={() => {
                                    setRoleTypeFilter("agent");
                                  }}
                                >
                                  Leader
                                </a>
                              </li>
                              <li>
                                <a
                                  class="dropdown-item"
                                  href="#"
                                  onClick={() => {
                                    setRoleTypeFilter("mini_agent");
                                  }}
                                >
                                  Ambassador
                                </a>
                              </li>
                            </ul>
                          ) : user?.role == "mega_agent" ? (
                            <ul class="dropdown-menu">
                              <li>
                                <a
                                  onClick={() => setRoleTypeFilter("")}
                                  class="dropdown-item"
                                  href="#"
                                >
                                  All Leaders
                                </a>
                              </li>
                              <li>
                                <a
                                  class="dropdown-item"
                                  href="#"
                                  onClick={() => {
                                    setRoleTypeFilter("super_agent");
                                  }}
                                >
                                  Super Leader
                                </a>
                              </li>
                              <li>
                                <a
                                  class="dropdown-item"
                                  href="#"
                                  onClick={() => {
                                    setRoleTypeFilter("agent");
                                  }}
                                >
                                  Leader
                                </a>
                              </li>
                              <li>
                                <a
                                  class="dropdown-item"
                                  href="#"
                                  onClick={() => {
                                    setRoleTypeFilter("mini_agent");
                                  }}
                                >
                                  Ambassador
                                </a>
                              </li>
                            </ul>
                          ) : user?.role == "super_agent" ? (
                            <ul class="dropdown-menu">
                              <li>
                                <a
                                  onClick={() => setRoleTypeFilter("")}
                                  class="dropdown-item"
                                  href="#"
                                >
                                  All Leaders
                                </a>
                              </li>
                              <li>
                                <a
                                  class="dropdown-item"
                                  href="#"
                                  onClick={() => {
                                    setRoleTypeFilter("agent");
                                  }}
                                >
                                  Leader
                                </a>
                              </li>
                              <li>
                                <a
                                  class="dropdown-item"
                                  href="#"
                                  onClick={() => {
                                    setRoleTypeFilter("mini_agent");
                                  }}
                                >
                                  Ambassador
                                </a>
                              </li>
                            </ul>
                          ) : user?.role == "agent" ? (
                            <ul class="dropdown-menu">
                              <li>
                                <a
                                  onClick={() => setRoleTypeFilter("")}
                                  class="dropdown-item"
                                  href="#"
                                >
                                  All Leaders
                                </a>
                              </li>

                              <li>
                                <a
                                  class="dropdown-item"
                                  href="#"
                                  onClick={() => {
                                    setRoleTypeFilter("mini_agent");
                                  }}
                                >
                                  Ambassador
                                </a>
                              </li>
                            </ul>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    </>
                  )}
                  <div class="dropdown">
                    <button
                      class="dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <div
                        onClick={() => {
                          // setLeaderFilter("");
                        }}
                        className="se-custom-flex-gap"
                      >
                        {leaderFilter ? leaderFilter + " " : "By Name "}

                        <img
                          src="\assets\arrow-down.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </div>
                    </button>
                    <ul class="dropdown-menu gycytctdtrctrc">
                      <div className="custom-option-field drop-in-search-sticky">
                        <input
                          value={search0}
                          onChange={(e) => GetSearch(e.target.value)}
                          type="search"
                          placeholder="Search"
                        />

                        <img
                          // onClick={() => SearchByName()}
                          style={{ cursor: "pointer" }}
                          src="\assets\search-icon.svg"
                          alt="img"
                          className="img-fluid search-icon"
                        />
                      </div>
                      {leaderNames?.length > 0
                        ? leaderNames?.map((item) => {
                            return (
                              <>
                                <li>
                                  <a
                                    class="dropdown-item"
                                    href="#"
                                    onClick={() => {
                                      setLeaderFilter(item?.name);
                                      setSearch0(item?.name);
                                    }}
                                  >
                                    {item?.name}
                                  </a>
                                </li>
                              </>
                            );
                          })
                        : null}
                    </ul>
                  </div>
                  {/* <button
                  className="reset-btn-global"
                  onClick={() => {
                    setRoleTypeFilter("");
                    setLeaderFilter("");
                    setSearchQuery("");
                  }}
                >
                  Reset
                </button> */}
                  <div class="dropdown allldrop">
                    <button
                      class="dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <div>
                        {activeFilter
                          ? "Active"
                          : inActiveFilter
                          ? "Inactive"
                          : "All"}
                        <img
                          src="\assets\arrow-down.svg"
                          alt="img"
                          className="img-fluid ms-2"
                        />
                      </div>
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          class="dropdown-item"
                          href="#"
                          onClick={() => {
                            setActiveFilter(false);
                            setInActiveFilter(false);
                          }}
                        >
                          All
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item"
                          href="#"
                          onClick={() => {
                            setActiveFilter(true);
                            setInActiveFilter(false);
                          }}
                        >
                          Active
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item"
                          href="#"
                          onClick={() => {
                            setActiveFilter(false);
                            setInActiveFilter(true);
                          }}
                        >
                          Inactive
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {agents?.length > 0 ? (
              <DataTable
                btcPrice={btcPrice}
                usdPrice={usdPrice}
                tomiPrice={tomiPrice}
                setBlockUnblock={setBlockUnblock}
                blockUnblock={blockUnblock}
                setUserDel={setUserDel}
                getNextData={getNextData}
                currentPage={currentPage}
                GetAllRounds={GetAgents}
                pageCount={pageCount}
                pages={pages}
                getPrevData={getPrevData}
                GetAgents={GetAgents}
                page={page}
                offset={offset}
                setOffset={setOffset}
                agents={agents}
                setAgents={setAgents}
                setShow={setShow}
                setRoutes={setRoutes}
                setOrderDirection={setOrderDirection}
                setOrderField={setOrderField}
                handlePageChange={handlePageChange}
                pagination={true}
              />
            ) : leadersLoader ? (
              <p
                className=""
                style={{
                  color: "#fff",
                  textAlign: "center",
                  marginTop: 100,
                }}
              >
                Loading...
              </p>
            ) : agents?.length == 0 ? (
              <p
                className=""
                style={{
                  color: "#fff",
                  textAlign: "center",
                  marginTop: 100,
                }}
              >
                No data found!
              </p>
            ) : null}
          </div>
        </section>
      )}
      <Modal
        className="delete-modal global-modal-scss"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{blockUnblock ? "Unblock" : "Block"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-modal-div">
            {blockUnblock ? (
              <img
                src="\assets\unblockuser.svg"
                alt="img"
                className="img-fluid"
              />
            ) : (
              <img
                src="\assets\blockuser.svg"
                alt="img"
                className="img-fluid"
              />
            )}
            <h6>
              Are you sure you want to {blockUnblock ? "Unblock" : "Block"}{" "}
              this?
            </h6>
            {/* <p>This will delete it permanently. You cannot undo this action.</p> */}
          </div>
          <div className="twice-btn">
            <button onClick={() => handleClose()} className="btn-cancel">
              Cancel
            </button>
            <button
              className={blockUnblock ? "btn-del btnunblockmodal" : "btn-del"}
              onClick={() => DeleteUser()}
            >
              {blockUnblock ? "Unblock" : "Block"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        className="add-mega-modal global-modal-scss"
        show={show1}
        onHide={handleClose1}
        centered
      >
        <Modal.Header closeButton>
          {user?.role === "admin" ? (
            <Modal.Title>Add God Leader</Modal.Title>
          ) : (
            <Modal.Title>Add Leader</Modal.Title>
          )}
          {/* {user?.role === "admin" && <Modal.Title>Add Mega Agents</Modal.Title>}
                    {user?.role === "admin" && <Modal.Title>Add Mega Agents</Modal.Title>} */}
        </Modal.Header>
        <Modal.Body>
          <div className="add-mega-div">
            <div className="option-field">
              <div className="material-textfield">
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter Leader’s Name"
                />
                <label>Leader’s Name</label>
              </div>
            </div>
            <div className="option-field">
              <div className="material-textfield">
                <input
                  type="text"
                  value={addr}
                  onChange={(e) => setAddr(e.target.value)}
                  placeholder="Enter Leader’s Wallet Address"
                />
                <label>Leader’s Wallet Address</label>
              </div>
            </div>
            <div className="option-field">
              <div className="material-textfield">
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  type="text"
                  placeholder="Enter Leader’s Email Address"
                />
                <label>Leader’s Email Address</label>
              </div>
            </div>
            {user?.role !== "admin" && (
              <div className="option-field">
                <div class="dropdown">
                  <button
                    class="btn btn-secondary dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {role === null
                      ? "Type of Leader"
                      : convertToTitleCase(role)}
                  </button>
                  {user?.role === "god_agent" && (
                    <ul class="dropdown-menu">
                      <li onClick={() => setRole("mega_agent")}>
                        <a class="dropdown-item" href="#">
                          Mega Leader
                        </a>
                      </li>
                      <li onClick={() => setRole("super_agent")}>
                        <a class="dropdown-item" href="#">
                          Super
                        </a>
                      </li>
                      <li onClick={() => setRole("agent")}>
                        <a class="dropdown-item" href="#">
                          Leader
                        </a>
                      </li>
                      <li onClick={() => setRole("mini_agent")}>
                        <a class="dropdown-item" href="#">
                          Ambassador
                        </a>
                      </li>
                    </ul>
                  )}
                  {user?.role === "mega_agent" && (
                    <ul class="dropdown-menu">
                      <li onClick={() => setRole("super_agent")}>
                        <a class="dropdown-item" href="#">
                          Super
                        </a>
                      </li>
                      <li onClick={() => setRole("agent")}>
                        <a class="dropdown-item" href="#">
                          Leader
                        </a>
                      </li>
                      <li onClick={() => setRole("mini_agent")}>
                        <a class="dropdown-item" href="#">
                          Ambassador
                        </a>
                      </li>
                    </ul>
                  )}
                  {user?.role === "super_agent" && (
                    <ul class="dropdown-menu">
                      <li onClick={() => setRole("agent")}>
                        <a class="dropdown-item" href="#">
                          Leader
                        </a>
                      </li>
                      <li onClick={() => setRole("mini_agent")}>
                        <a class="dropdown-item" href="#">
                          Ambassador
                        </a>
                      </li>
                    </ul>
                  )}
                  {user?.role === "agent" && (
                    <ul class="dropdown-menu">
                      <li onClick={() => setRole("mini_agent")}>
                        <a class="dropdown-item" href="#">
                          Ambassador
                        </a>
                      </li>
                    </ul>
                  )}
                </div>
              </div>
            )}
          </div>
          <p
            style={{
              color: "red",
              textAlign: "center",
              marginTop: -15,
              marginBottom: 15,
            }}
          >
            {error}
          </p>
          <div className="twice-btn">
            <button
              onClick={() => {
                handleClose1();
                setError("");
              }}
              className="btn-cancel"
            >
              Back
            </button>
            <button
              disabled={loader}
              className="btn-add"
              onClick={() => AddAgent()}
            >
              {loader ? "Adding..." : "Add"}
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="add-mega-modal global-modal-scss"
        show={show2}
        onHide={handleClose2}
        centered
      >
        <Modal.Header closeButton>
          {user?.role === "admin" ? (
            <Modal.Title>Add Mega Leaders</Modal.Title>
          ) : (
            <Modal.Title>Add Leaders</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          <div className="add-mega-div">
            {user?.role === "admin" ? (
              <h6>God Leader has been added</h6>
            ) : (
              <h6>
                {convertToTitleCase(role)}{" "}
                {convertToTitleCase(role) === "Leader"
                  ? "has been added"
                  : "Leader has been added"}{" "}
              </h6>
            )}
          </div>
          <div className="twice-btn">
            <button
              onClick={() => handleClose2()}
              className="btn-add"
              style={{ flex: "auto" }}
            >
              Okay
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AllAgents;
