import React, { useState, useEffect } from "react";
import { Accordion } from "react-bootstrap";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactPaginate from "react-paginate";

const DataTable = ({
  setActiveTab,
  activeTab,
  transactions,
  transactionApiCalled,
  buyersData,
  buyersApiCalled,
  orderDirectionTransactions,
  orderFieldTransactions,
  setOrderDirectionTransactions,
  setOrderFieldTransactions,
  orderDirectionBuyers,
  orderFieldBuyers,
  setOrderDirectionBuyers,
  setOrderFieldBuyers,
  handlePageChangeTransactions,
  handlePageChangeBuyers,
  pageBuyers,
  pageTransactions,
  pageCountBuyers,
  pageCountTransactions,
}) => {
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const [copy, setCopy] = useState(false);
  const textCopiedFun2 = () => {
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 1000);
  };
  return (
    <>
      <section className="datatable">
        <div className="main-heading">
          <ul class="nav nav-tabs viewdetail-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                className={`nav-link ${
                  activeTab === "home-tab" ? "active" : ""
                }`}
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home-tab-pane"
                type="button"
                role="tab"
                aria-controls="home-tab-pane"
                aria-selected={activeTab === "home-tab"}
                onClick={() => handleTabClick("home-tab")}
              >
                Transactions
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                className={`nav-link ${
                  activeTab === "profile-tab" ? "active" : ""
                }`}
                id="profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#profile-tab-pane"
                type="button"
                role="tab"
                aria-controls="profile-tab-pane"
                aria-selected={activeTab === "profile-tab"}
                onClick={() => handleTabClick("profile-tab")}
              >
                Testnet Buyers
              </button>
            </li>
          </ul>
        </div>
        <div class="tab-content" id="myTabContent">
          <div
            class="tab-pane fade show active"
            id="home-tab-pane"
            role="tabpanel"
            aria-labelledby="home-tab"
            tabindex="0"
          >
            <div className="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>
                      <div className="parent-tag">
                        Date{" "}
                        <div
                          className="filter-btn"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setOrderFieldTransactions("transactionTime");
                            setOrderDirectionTransactions((prev) =>
                              prev == -1 ? 1 : prev == 1 ? -1 : -1
                            );
                          }}
                        >
                          <svg
                            width="9"
                            height="6"
                            viewBox="0 0 9 6"
                            fill={
                              orderFieldTransactions == "transactionTime" &&
                              orderDirectionTransactions == -1
                                ? "#242424"
                                : "#6b6b6b"
                            }
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                              fill={
                                orderFieldTransactions == "transactionTime" &&
                                orderDirectionTransactions == -1
                                  ? "#242424"
                                  : "#6b6b6b"
                              }
                            />
                          </svg>
                          <svg
                            width="9"
                            height="6"
                            viewBox="0 0 9 6"
                            fill={
                              orderFieldTransactions == "transactionTime" &&
                              orderDirectionTransactions == 1
                                ? "#242424"
                                : "#6b6b6b"
                            }
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                              fill={
                                orderFieldTransactions == "transactionTime" &&
                                orderDirectionTransactions == 1
                                  ? "#242424"
                                  : "#6b6b6b"
                              }
                            />
                          </svg>
                        </div>
                      </div>
                    </th>
                    <th>
                      <div className="parent-tag">
                        Hash{" "}
                        <div
                          className="filter-btn"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setOrderFieldTransactions("transactionHash");
                            setOrderDirectionTransactions((prev) =>
                              prev == -1 ? 1 : prev == 1 ? -1 : -1
                            );
                          }}
                        >
                          <svg
                            width="9"
                            height="6"
                            viewBox="0 0 9 6"
                            fill={
                              orderFieldTransactions == "transactionHash" &&
                              orderDirectionTransactions == -1
                                ? "#242424"
                                : "#6b6b6b"
                            }
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                              fill={
                                orderFieldTransactions == "transactionHash" &&
                                orderDirectionTransactions == -1
                                  ? "#242424"
                                  : "#6b6b6b"
                              }
                            />
                          </svg>
                          <svg
                            width="9"
                            height="6"
                            viewBox="0 0 9 6"
                            fill={
                              orderFieldTransactions == "transactionHash" &&
                              orderDirectionTransactions == 1
                                ? "#242424"
                                : "#6b6b6b"
                            }
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                              fill={
                                orderFieldTransactions == "transactionHash" &&
                                orderDirectionTransactions == 1
                                  ? "#242424"
                                  : "#6b6b6b"
                              }
                            />
                          </svg>
                        </div>
                      </div>
                    </th>
                    <th>
                      <div className="parent-tag">
                        Wallet address{" "}
                        <div
                          className="filter-btn"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setOrderFieldTransactions("by");
                            setOrderDirectionTransactions((prev) =>
                              prev == -1 ? 1 : prev == 1 ? -1 : -1
                            );
                          }}
                        >
                          <svg
                            width="9"
                            height="6"
                            viewBox="0 0 9 6"
                            fill={
                              orderFieldTransactions == "by" &&
                              orderDirectionTransactions == -1
                                ? "#242424"
                                : "#6b6b6b"
                            }
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                              fill={
                                orderFieldTransactions == "by" &&
                                orderDirectionTransactions == -1
                                  ? "#242424"
                                  : "#6b6b6b"
                              }
                            />
                          </svg>
                          <svg
                            width="9"
                            height="6"
                            viewBox="0 0 9 6"
                            fill={
                              orderFieldTransactions == "by" &&
                              orderDirectionTransactions == 1
                                ? "#242424"
                                : "#6b6b6b"
                            }
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                              fill={
                                orderFieldTransactions == "by" &&
                                orderDirectionTransactions == 1
                                  ? "#242424"
                                  : "#6b6b6b"
                              }
                            />
                          </svg>
                        </div>
                      </div>
                    </th>
                    <th>
                      <div className="parent-tag">
                        Purchase limit{" "}
                        <div
                          className="filter-btn"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setOrderFieldTransactions("purchaseLimit");
                            setOrderDirectionTransactions((prev) =>
                              prev == -1 ? 1 : prev == 1 ? -1 : -1
                            );
                          }}
                        >
                          <svg
                            width="9"
                            height="6"
                            viewBox="0 0 9 6"
                            fill={
                              orderFieldTransactions == "purchaseLimit" &&
                              orderDirectionTransactions == -1
                                ? "#242424"
                                : "#6b6b6b"
                            }
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                              fill={
                                orderFieldTransactions == "purchaseLimit" &&
                                orderDirectionTransactions == -1
                                  ? "#242424"
                                  : "#6b6b6b"
                              }
                            />
                          </svg>
                          <svg
                            width="9"
                            height="6"
                            viewBox="0 0 9 6"
                            fill={
                              orderFieldTransactions == "purchaseLimit" &&
                              orderDirectionTransactions == 1
                                ? "#242424"
                                : "#6b6b6b"
                            }
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                              fill={
                                orderFieldTransactions == "purchaseLimit" &&
                                orderDirectionTransactions == 1
                                  ? "#242424"
                                  : "#6b6b6b"
                              }
                            />
                          </svg>
                        </div>
                      </div>
                    </th>
                    <th>
                      <div className="parent-tag">
                        Amount purchased{" "}
                        <div
                          className="filter-btn"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setOrderFieldTransactions("amountInvested");
                            setOrderDirectionTransactions((prev) =>
                              prev == -1 ? 1 : prev == 1 ? -1 : -1
                            );
                          }}
                        >
                          <svg
                            width="9"
                            height="6"
                            viewBox="0 0 9 6"
                            fill={
                              orderFieldTransactions == "amountInvested" &&
                              orderDirectionTransactions == -1
                                ? "#242424"
                                : "#6b6b6b"
                            }
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                              fill={
                                orderFieldTransactions == "amountInvested" &&
                                orderDirectionTransactions == -1
                                  ? "#242424"
                                  : "#6b6b6b"
                              }
                            />
                          </svg>
                          <svg
                            width="9"
                            height="6"
                            viewBox="0 0 9 6"
                            fill={
                              orderFieldTransactions == "amountInvested" &&
                              orderDirectionTransactions == 1
                                ? "#242424"
                                : "#6b6b6b"
                            }
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                              fill={
                                orderFieldTransactions == "amountInvested" &&
                                orderDirectionTransactions == 1
                                  ? "#242424"
                                  : "#6b6b6b"
                              }
                            />
                          </svg>
                        </div>
                      </div>
                    </th>
                    <th>
                      <div className="parent-tag">
                        Tokens{" "}
                        <div
                          className="filter-btn"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setOrderFieldTransactions("dopPurchased");
                            setOrderDirectionTransactions((prev) =>
                              prev == -1 ? 1 : prev == 1 ? -1 : -1
                            );
                          }}
                        >
                          <svg
                            width="9"
                            height="6"
                            viewBox="0 0 9 6"
                            fill={
                              orderFieldTransactions == "dopPurchased" &&
                              orderDirectionTransactions == -1
                                ? "#242424"
                                : "#6b6b6b"
                            }
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                              fill={
                                orderFieldTransactions == "dopPurchased" &&
                                orderDirectionTransactions == -1
                                  ? "#242424"
                                  : "#6b6b6b"
                              }
                            />
                          </svg>
                          <svg
                            width="9"
                            height="6"
                            viewBox="0 0 9 6"
                            fill={
                              orderFieldTransactions == "dopPurchased" &&
                              orderDirectionTransactions == 1
                                ? "#242424"
                                : "#6b6b6b"
                            }
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                              fill={
                                orderFieldTransactions == "dopPurchased" &&
                                orderDirectionTransactions == 1
                                  ? "#242424"
                                  : "#6b6b6b"
                              }
                            />
                          </svg>
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                {transactionApiCalled ? (
                  <>
                    {transactions?.length > 0 ? (
                      transactions?.map((item) => {
                        return (
                          <>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="twice-text">
                                    <h6>
                                      {moment(item?.transactionTime)
                                        .utc()
                                        .format("DD/MM/YYYY")}
                                    </h6>
                                    <p>
                                      {" "}
                                      {moment(item?.transactionTime)
                                        .utc()

                                        .format("hh:mm A")}{" "}
                                      UTC
                                    </p>
                                  </div>
                                </td>
                                <td>
                                  {item?.transactionHash?.slice(0, 6)}...
                                  {item?.transactionHash?.slice(
                                    item?.transactionHash?.length - 3,
                                    item?.transactionHash?.length
                                  )}
                                  <a
                                    href={
                                      "https://etherscan.io/tx/" +
                                      item?.transactionHash
                                    }
                                    target="_blank"
                                  >
                                    <img
                                      src="\assets\link-icon.svg"
                                      alt="img"
                                      className="img-fluid"
                                    />
                                  </a>
                                  {/* <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="21"
                                    height="21"
                                    viewBox="0 0 21 21"
                                    fill="none"
                                  >
                                    <path
                                      d="M8.66683 4.66671H7.16683C6.23341 4.66671 5.76635 4.66671 5.40983 4.84836C5.09623 5.00815 4.84144 5.26293 4.68166 5.57654C4.5 5.93306 4.5 6.40012 4.5 7.33354V13.6669C4.5 14.6003 4.5 15.0668 4.68166 15.4233C4.84144 15.7369 5.09623 15.9921 5.40983 16.1519C5.766 16.3334 6.23249 16.3334 7.16409 16.3334H13.5026C14.4342 16.3334 14.9 16.3334 15.2562 16.1519C15.5698 15.9921 15.8254 15.7366 15.9852 15.423C16.1667 15.0669 16.1667 14.6009 16.1667 13.6693V12.1667M17 8.00004V3.83337M17 3.83337H12.8333M17 3.83337L11.1667 9.66671"
                                      stroke="white"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg> */}
                                </td>
                                <td>
                                  {copy ? (
                                    <span className="adadasdasdasdasdasdsad">
                                      {item?.by?.slice(0, 6)}...
                                      {item?.by?.slice(
                                        item?.by?.length - 3,
                                        item?.by?.length
                                      )}{" "}
                                      <svg
                                        style={{ cursor: "pointer" }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        className=""
                                      >
                                        <path
                                          d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                                          stroke="white"
                                          stroke-width="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                                          stroke="white"
                                          stroke-width="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </span>
                                  ) : (
                                    <>
                                      {item?.by && (
                                        <CopyToClipboard
                                          text={item?.by}
                                          onCopy={textCopiedFun2}
                                        >
                                          <span className="adadasdasdasdasdasdsad">
                                            {item?.by?.slice(0, 6)}...
                                            {item?.by?.slice(
                                              item?.by?.length - 3,
                                              item?.by?.length
                                            )}{" "}
                                            <svg
                                              style={{ cursor: "pointer" }}
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="20"
                                              height="20"
                                              viewBox="0 0 20 20"
                                              fill="none"
                                              className=""
                                            >
                                              <path
                                                d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                                                stroke="white"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              />
                                              <path
                                                d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                                                stroke="white"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              />
                                            </svg>
                                          </span>
                                        </CopyToClipboard>
                                      )}
                                    </>
                                  )}
                                </td>
                                <td>{item?.purchaseLimit} ETH</td>
                                <td>
                                  {parseFloat(item?.amountInvested)?.toFixed(5)}{" "}
                                  ETH
                                </td>
                                <td>
                                  {parseFloat(item?.dopPurchased)
                                    .toFixed(2)
                                    ?.toLocaleString()}{" "}
                                  DOP
                                </td>
                              </tr>
                            </tbody>
                          </>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="6">
                          <p
                            className=""
                            style={{
                              color: "#fff",
                              textAlign: "center",
                              marginTop: 100,
                              background: "transparent",
                            }}
                          >
                            No data found!
                          </p>
                        </td>
                      </tr>
                    )}
                  </>
                ) : (
                  <tr>
                    <td colSpan="6">
                      <p
                        className=""
                        style={{
                          color: "#fff",
                          textAlign: "center",
                          marginTop: 100,
                          background: "transparent",
                        }}
                      >
                        Loading...
                      </p>
                    </td>
                  </tr>
                )}
              </table>
              <div className="footer-content mt-2">
                <div className="left-f">
                  {/* <h6>SHOWING 1-10 OF 145</h6> */}
                  {/* <h6>SHOWING 1-10 OF 145</h6> */}
                </div>
                <div className="right-f">
                  <nav aria-label="Page navigation example">
                    <ul class="pagination">
                      <nav aria-label="Page navigation example">
                        <ul class="text-center">
                          <ReactPaginate
                            previousLabel={
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                                fill="none"
                              >
                                <path
                                  d="M10 13L5 8L10 3"
                                  stroke="#fff"
                                  stroke-width="1.5"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            }
                            nextLabel={
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                                fill="none"
                              >
                                <path
                                  d="M5 13L10 8L5 3"
                                  stroke="#fff"
                                  stroke-width="1.5"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            }
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            pageCount={pageCountTransactions}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageChangeTransactions}
                            containerClassName="pagination"
                            activeClassName="active"
                            forcePage={pageTransactions}
                          ></ReactPaginate>
                        </ul>
                      </nav>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
            <div className="mobiledesignss d-none">
              <Accordion>
                {transactionApiCalled ? (
                  <>
                    {transactions?.length > 0 ? (
                      transactions?.map((item) => {
                        return (
                          <>
                            <div className="new-accordionmobile d-none">
                              <div className="upper-body">
                                <div className="inner-text">
                                  <h6>
                                    {moment(item?.transactionTime)
                                      .utc()
                                      .format("DD/MM/YYYY")}
                                  </h6>
                                  <p>
                                    {" "}
                                    {moment(item?.transactionTime)
                                      .utc()

                                      .format("hh:mm A")}{" "}
                                    UTC
                                  </p>
                                </div>
                              </div>
                              <div className="bottom-body">
                                <Accordion>
                                  <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                      More Info{" "}
                                      <img
                                        src="\assets\arrow-down.svg"
                                        alt="img"
                                        className="img-fluid"
                                      />
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div className="inner-textt">
                                        <p>Hash</p>
                                        <h6>
                                          <a
                                            href={
                                              "https://etherscan.io/tx/" +
                                              item?.transactionHash
                                            }
                                            target="_blank"
                                          >
                                            Etherscan
                                          </a>
                                        </h6>
                                      </div>
                                      <div className="inner-textt">
                                        <p>Wallet Address</p>
                                        {copy ? (
                                          <h6 className="adadasdasdasdasdasdsad">
                                            {item?.by?.slice(0, 6)}...
                                            {item?.by?.slice(
                                              item?.by?.length - 3,
                                              item?.by?.length
                                            )}{" "}
                                            <svg
                                              style={{ cursor: "pointer" }}
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="20"
                                              height="20"
                                              viewBox="0 0 20 20"
                                              fill="none"
                                              className=""
                                            >
                                              <path
                                                d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                                                stroke="white"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              />
                                              <path
                                                d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                                                stroke="white"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              />
                                            </svg>
                                          </h6>
                                        ) : (
                                          <>
                                            {item?.by && (
                                              <CopyToClipboard
                                                text={item?.by}
                                                onCopy={textCopiedFun2}
                                              >
                                                <h6 className="adadasdasdasdasdasdsad">
                                                  {item?.by?.slice(0, 6)}...
                                                  {item?.by?.slice(
                                                    item?.by?.length - 3,
                                                    item?.by?.length
                                                  )}{" "}
                                                  <svg
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 20 20"
                                                    fill="none"
                                                    className=""
                                                  >
                                                    <path
                                                      d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                                                      stroke="white"
                                                      stroke-width="1.5"
                                                      stroke-linecap="round"
                                                      stroke-linejoin="round"
                                                    />
                                                    <path
                                                      d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                                                      stroke="white"
                                                      stroke-width="1.5"
                                                      stroke-linecap="round"
                                                      stroke-linejoin="round"
                                                    />
                                                  </svg>
                                                </h6>
                                              </CopyToClipboard>
                                            )}
                                          </>
                                        )}
                                      </div>
                                      <div className="inner-textt">
                                        <p>Purchase limit</p>
                                        <h6>{item?.purchaseLimit} ETH</h6>
                                      </div>
                                      <div className="inner-textt">
                                        <p>Amount purchased</p>
                                        <h6>
                                          {" "}
                                          {parseFloat(
                                            item?.amountInvested
                                          )?.toFixed(5)}{" "}
                                          ETH
                                        </h6>
                                      </div>
                                      <div className="inner-textt">
                                        <p>Tokens</p>
                                        <h6>
                                          {" "}
                                          {parseFloat(item?.dopPurchased)
                                            .toFixed(2)
                                            ?.toLocaleString()}{" "}
                                          DOP
                                        </h6>
                                      </div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                              </div>
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <p
                        className=""
                        style={{
                          color: "#fff",
                          textAlign: "center",
                          marginTop: 100,
                        }}
                      >
                        No data found!
                      </p>
                    )}
                  </>
                ) : (
                  <p
                    className=""
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      marginTop: 100,
                    }}
                  >
                    Loading...
                  </p>
                )}
              </Accordion>
              <div className="footer-content mt-2">
                <div className="left-f">
                  {/* <h6>SHOWING 1-10 OF 145</h6> */}
                  {/* <h6>SHOWING 1-10 OF 145</h6> */}
                </div>
                <div className="right-f">
                  <nav aria-label="Page navigation example">
                    <ul class="pagination">
                      <nav aria-label="Page navigation example">
                        <ul class="text-center">
                          <ReactPaginate
                            previousLabel={
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                                fill="none"
                              >
                                <path
                                  d="M10 13L5 8L10 3"
                                  stroke="#fff"
                                  stroke-width="1.5"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            }
                            nextLabel={
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                                fill="none"
                              >
                                <path
                                  d="M5 13L10 8L5 3"
                                  stroke="#fff"
                                  stroke-width="1.5"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            }
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            pageCount={pageCountBuyers}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageChangeBuyers}
                            containerClassName="pagination"
                            activeClassName="active"
                            forcePage={pageBuyers}
                          ></ReactPaginate>
                        </ul>
                      </nav>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="profile-tab-pane"
            role="tabpanel"
            aria-labelledby="profile-tab"
            tabindex="1"
          >
            <div className="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>
                      <div className="parent-tag">
                        Wallet address{" "}
                        <div
                          className="filter-btn"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setOrderFieldBuyers("externalWalletAddress");
                            setOrderDirectionBuyers((prev) =>
                              prev == -1 ? 1 : prev == 1 ? -1 : -1
                            );
                          }}
                        >
                          <svg
                            width="9"
                            height="6"
                            viewBox="0 0 9 6"
                            fill={
                              orderFieldBuyers == "externalWalletAddress" &&
                              orderDirectionBuyers == -1
                                ? "#242424"
                                : "#6b6b6b"
                            }
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                              fill={
                                orderFieldBuyers == "externalWalletAddress" &&
                                orderDirectionBuyers == -1
                                  ? "#242424"
                                  : "#6b6b6b"
                              }
                            />
                          </svg>
                          <svg
                            width="9"
                            height="6"
                            viewBox="0 0 9 6"
                            fill={
                              orderFieldBuyers == "externalWalletAddress" &&
                              orderDirectionBuyers == 1
                                ? "#242424"
                                : "#6b6b6b"
                            }
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                              fill={
                                orderFieldBuyers == "externalWalletAddress" &&
                                orderDirectionBuyers == 1
                                  ? "#242424"
                                  : "#6b6b6b"
                              }
                            />
                          </svg>
                        </div>
                      </div>
                    </th>
                    <th>
                      <div className="parent-tag">
                        Purchase limit{" "}
                        <div
                          className="filter-btn"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setOrderFieldBuyers("reward");
                            setOrderDirectionBuyers((prev) =>
                              prev == -1 ? 1 : prev == 1 ? -1 : -1
                            );
                          }}
                        >
                          <svg
                            width="9"
                            height="6"
                            viewBox="0 0 9 6"
                            fill={
                              orderFieldBuyers == "reward" &&
                              orderDirectionBuyers == -1
                                ? "#242424"
                                : "#6b6b6b"
                            }
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                              fill={
                                orderFieldBuyers == "reward" &&
                                orderDirectionBuyers == -1
                                  ? "#242424"
                                  : "#6b6b6b"
                              }
                            />
                          </svg>
                          <svg
                            width="9"
                            height="6"
                            viewBox="0 0 9 6"
                            fill={
                              orderFieldBuyers == "reward" &&
                              orderDirectionBuyers == 1
                                ? "#242424"
                                : "#6b6b6b"
                            }
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                              fill={
                                orderFieldBuyers == "reward" &&
                                orderDirectionBuyers == 1
                                  ? "#242424"
                                  : "#6b6b6b"
                              }
                            />
                          </svg>
                        </div>
                      </div>
                    </th>
                    <th>
                      <div className="parent-tag">
                        Amount purchased{" "}
                        <div
                          className="filter-btn"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setOrderFieldBuyers("totalPurchased");
                            setOrderDirectionBuyers((prev) =>
                              prev == -1 ? 1 : prev == 1 ? -1 : -1
                            );
                          }}
                        >
                          <svg
                            width="9"
                            height="6"
                            viewBox="0 0 9 6"
                            fill={
                              orderFieldBuyers == "totalPurchased" &&
                              orderDirectionBuyers == -1
                                ? "#242424"
                                : "#6b6b6b"
                            }
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                              fill={
                                orderFieldBuyers == "totalPurchased" &&
                                orderDirectionBuyers == -1
                                  ? "#242424"
                                  : "#6b6b6b"
                              }
                            />
                          </svg>
                          <svg
                            width="9"
                            height="6"
                            viewBox="0 0 9 6"
                            fill={
                              orderFieldBuyers == "totalPurchased" &&
                              orderDirectionBuyers == 1
                                ? "#242424"
                                : "#6b6b6b"
                            }
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                              fill={
                                orderFieldBuyers == "totalPurchased" &&
                                orderDirectionBuyers == 1
                                  ? "#242424"
                                  : "#6b6b6b"
                              }
                            />
                          </svg>
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                {buyersApiCalled ? (
                  <>
                    {buyersData?.length > 0 ? (
                      buyersData?.map((item) => {
                        return (
                          <>
                            <tbody>
                              <tr>
                                <td>
                                  {copy ? (
                                    <h6 className="adadasdasdasdasdasdsad">
                                      {item?.externalWalletAddress?.slice(0, 6)}
                                      ...
                                      {item?.externalWalletAddress?.slice(
                                        item?.externalWalletAddress?.length - 3,
                                        item?.externalWalletAddress?.length
                                      )}{" "}
                                      <svg
                                        style={{ cursor: "pointer" }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        className=""
                                      >
                                        <path
                                          d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                                          stroke="white"
                                          stroke-width="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                                          stroke="white"
                                          stroke-width="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </h6>
                                  ) : (
                                    <>
                                      {item?.externalWalletAddress && (
                                        <CopyToClipboard
                                          text={item?.externalWalletAddress}
                                          onCopy={textCopiedFun2}
                                        >
                                          <h6 className="adadasdasdasdasdasdsad">
                                            {item?.externalWalletAddress?.slice(
                                              0,
                                              6
                                            )}
                                            ...
                                            {item?.externalWalletAddress?.slice(
                                              item?.externalWalletAddress
                                                ?.length - 3,
                                              item?.externalWalletAddress
                                                ?.length
                                            )}{" "}
                                            <svg
                                              style={{
                                                cursor: "pointer",
                                              }}
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="20"
                                              height="20"
                                              viewBox="0 0 20 20"
                                              fill="none"
                                              className=""
                                            >
                                              <path
                                                d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                                                stroke="white"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              />
                                              <path
                                                d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                                                stroke="white"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              />
                                            </svg>
                                          </h6>
                                        </CopyToClipboard>
                                      )}
                                    </>
                                  )}
                                </td>
                                <td>
                                  {parseFloat(item?.reward)?.toFixed(5)} ETH
                                </td>
                                <td>
                                  {item?.totalPurchased > 0
                                    ? parseFloat(item?.totalPurchased)?.toFixed(
                                        5
                                      )
                                    : "0"}{" "}
                                  ETH
                                </td>
                              </tr>
                            </tbody>
                          </>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="6">
                          <p
                            className=""
                            style={{
                              color: "#fff",
                              textAlign: "center",
                              marginTop: 100,
                              background: "transparent",
                            }}
                          >
                            No data found!
                          </p>
                        </td>
                      </tr>
                    )}
                  </>
                ) : (
                  <tr>
                    <td colSpan="6">
                      <p
                        className=""
                        style={{
                          color: "#fff",
                          textAlign: "center",
                          marginTop: 100,
                          background: "transparent",
                        }}
                      >
                        Loading...
                      </p>
                    </td>
                  </tr>
                )}
              </table>

              <div className="footer-content mt-2">
                <div className="left-f">
                  {/* <h6>SHOWING 1-10 OF 145</h6> */}
                  {/* <h6>SHOWING 1-10 OF 145</h6> */}
                </div>
                <div className="right-f">
                  <nav aria-label="Page navigation example">
                    <ul class="pagination">
                      <nav aria-label="Page navigation example">
                        <ul class="text-center">
                          <ReactPaginate
                            previousLabel={
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                                fill="none"
                              >
                                <path
                                  d="M10 13L5 8L10 3"
                                  stroke="#fff"
                                  stroke-width="1.5"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            }
                            nextLabel={
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                                fill="none"
                              >
                                <path
                                  d="M5 13L10 8L5 3"
                                  stroke="#fff"
                                  stroke-width="1.5"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            }
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            pageCount={pageCountBuyers}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageChangeBuyers}
                            containerClassName="pagination"
                            activeClassName="active"
                            forcePage={pageBuyers}
                          ></ReactPaginate>
                        </ul>
                      </nav>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
            <div className="mobiledesignss d-none">
              <Accordion>
                {buyersApiCalled ? (
                  <>
                    {buyersData?.length > 0 ? (
                      buyersData?.map((item) => {
                        return (
                          <>
                            <div className="new-accordionmobile d-none">
                              <div className="upper-body">
                                <div className="inner-text">
                                  <p>Wallet Address</p>
                                  <h6>
                                    {" "}
                                    {copy ? (
                                      <h6 className="adadasdasdasdasdasdsad">
                                        {item?.externalWalletAddress?.slice(
                                          0,
                                          6
                                        )}
                                        ...
                                        {item?.externalWalletAddress?.slice(
                                          item?.externalWalletAddress?.length -
                                            3,
                                          item?.externalWalletAddress?.length
                                        )}{" "}
                                        <svg
                                          style={{ cursor: "pointer" }}
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                          className=""
                                        >
                                          <path
                                            d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                                            stroke="white"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                          <path
                                            d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                                            stroke="white"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                        </svg>
                                      </h6>
                                    ) : (
                                      <>
                                        {item?.externalWalletAddress && (
                                          <CopyToClipboard
                                            text={item?.externalWalletAddress}
                                            onCopy={textCopiedFun2}
                                          >
                                            <h6 className="adadasdasdasdasdasdsad">
                                              {item?.externalWalletAddress?.slice(
                                                0,
                                                6
                                              )}
                                              ...
                                              {item?.externalWalletAddress?.slice(
                                                item?.externalWalletAddress
                                                  ?.length - 3,
                                                item?.externalWalletAddress
                                                  ?.length
                                              )}{" "}
                                              <svg
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                className=""
                                              >
                                                <path
                                                  d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                                                  stroke="white"
                                                  stroke-width="1.5"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                />
                                                <path
                                                  d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                                                  stroke="white"
                                                  stroke-width="1.5"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                />
                                              </svg>
                                            </h6>
                                          </CopyToClipboard>
                                        )}
                                      </>
                                    )}
                                  </h6>
                                </div>
                              </div>
                              <div className="bottom-body">
                                <Accordion>
                                  <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                      More Info{" "}
                                      <img
                                        src="\assets\arrow-down.svg"
                                        alt="img"
                                        className="img-fluid"
                                      />
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div className="inner-textt">
                                        <p>Wallet Address</p>
                                        <h6>
                                          {copy ? (
                                            <h6 className="adadasdasdasdasdasdsad">
                                              {item?.externalWalletAddress?.slice(
                                                0,
                                                6
                                              )}
                                              ...
                                              {item?.externalWalletAddress?.slice(
                                                item?.externalWalletAddress
                                                  ?.length - 3,
                                                item?.externalWalletAddress
                                                  ?.length
                                              )}{" "}
                                              <svg
                                                style={{ cursor: "pointer" }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                className=""
                                              >
                                                <path
                                                  d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                                                  stroke="white"
                                                  stroke-width="1.5"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                />
                                                <path
                                                  d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                                                  stroke="white"
                                                  stroke-width="1.5"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                />
                                              </svg>
                                            </h6>
                                          ) : (
                                            <>
                                              {item?.externalWalletAddress && (
                                                <CopyToClipboard
                                                  text={
                                                    item?.externalWalletAddress
                                                  }
                                                  onCopy={textCopiedFun2}
                                                >
                                                  <h6 className="adadasdasdasdasdasdsad">
                                                    {item?.externalWalletAddress?.slice(
                                                      0,
                                                      6
                                                    )}
                                                    ...
                                                    {item?.externalWalletAddress?.slice(
                                                      item
                                                        ?.externalWalletAddress
                                                        ?.length - 3,
                                                      item
                                                        ?.externalWalletAddress
                                                        ?.length
                                                    )}{" "}
                                                    <svg
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="20"
                                                      height="20"
                                                      viewBox="0 0 20 20"
                                                      fill="none"
                                                      className=""
                                                    >
                                                      <path
                                                        d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                                                        stroke="white"
                                                        stroke-width="1.5"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                      />
                                                      <path
                                                        d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                                                        stroke="white"
                                                        stroke-width="1.5"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                      />
                                                    </svg>
                                                  </h6>
                                                </CopyToClipboard>
                                              )}
                                            </>
                                          )}
                                        </h6>
                                      </div>
                                      <div className="inner-textt">
                                        <p>Purchase limit</p>
                                        <h6>
                                          {" "}
                                          {parseFloat(item?.reward)?.toFixed(
                                            5
                                          )}{" "}
                                          ETH
                                        </h6>
                                      </div>
                                      <div className="inner-textt">
                                        <p>Amount purchased</p>
                                        <h6>
                                          {item?.totalPurchased > 0
                                            ? parseFloat(
                                                item?.totalPurchased
                                              )?.toFixed(5)
                                            : "0"}
                                          ETH
                                        </h6>
                                      </div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                              </div>
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <p
                        className=""
                        style={{
                          color: "#fff",
                          textAlign: "center",
                          marginTop: 100,
                        }}
                      >
                        No data found!
                      </p>
                    )}
                  </>
                ) : (
                  <p
                    className=""
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      marginTop: 100,
                    }}
                  >
                    Loading...
                  </p>
                )}
              </Accordion>
              <div className="footer-content mt-2">
                <div className="left-f">
                  {/* <h6>SHOWING 1-10 OF 145</h6> */}
                  {/* <h6>SHOWING 1-10 OF 145</h6> */}
                </div>
                <div className="right-f">
                  <nav aria-label="Page navigation example">
                    <ul class="pagination">
                      <nav aria-label="Page navigation example">
                        <ul class="text-center">
                          <ReactPaginate
                            previousLabel={
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                                fill="none"
                              >
                                <path
                                  d="M10 13L5 8L10 3"
                                  stroke="#fff"
                                  stroke-width="1.5"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            }
                            nextLabel={
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                                fill="none"
                              >
                                <path
                                  d="M5 13L10 8L5 3"
                                  stroke="#fff"
                                  stroke-width="1.5"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            }
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            pageCount={pageCountBuyers}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageChangeBuyers}
                            containerClassName="pagination"
                            activeClassName="active"
                            forcePage={pageBuyers}
                          ></ReactPaginate>
                        </ul>
                      </nav>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DataTable;
