import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import {
  getclaimscontract,
  getclaimsnewcontract,
} from "../../utils/contractHelpers";
import { getClaimNewLatest } from "../../utils/contractHelpers";
const AllowClaimHook = () => {
  const web3 = useWeb3();
  const tokenAddress = environment.Claims;
  const contract = getclaimscontract(tokenAddress, web3);
  const tokenAddressNew = environment.ClaimsNew;
  const contractNew = getclaimsnewcontract(tokenAddressNew, web3);
  const tokenAddressTomi = environment.claimNewLatest;
  const contractNewLatest = getClaimNewLatest(tokenAddressTomi, web3);
  const allowClaimHook = useCallback(
    async (account, round, tokensAddressArray) => {
      if (parseInt(round) < 3) {
        try {
          const gas = await contract.methods
            .claim(round)
            .estimateGas({ from: account });
          const details = await contract.methods.claim(round).send({
            from: account,
            gas,
          });
          return details;
        } catch (error) {
          throw error;
        }
      } else if (parseInt(round) > 6) {
        try {
          const gas = await contractNewLatest.methods
            .claim(round, tokensAddressArray)
            .estimateGas({ from: account });
          const details = await contractNewLatest.methods
            .claim(round, tokensAddressArray)
            .send({
              from: account,
              gas,
            });
          return details;
        } catch (error) {
          throw error;
        }
      } else {
        try {
          const gas = await contractNew.methods
            .claim(round)
            .estimateGas({ from: account });
          const details = await contractNew.methods.claim(round).send({
            from: account,
            gas,
          });
          return details;
        } catch (error) {
          throw error;
        }
      }
    },
    [contract]
  );
  return { allowClaimHook: allowClaimHook };
};
export default AllowClaimHook;
