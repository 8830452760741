import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import { getdoproundcreatecontractold } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";

const TimerNFT = () => {
  const [balance, setBalance] = useState(null);
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const tokenAddress = Environment.CreateDopRound;
  const contract = getdoproundcreatecontractold(tokenAddress, web3);

  const timerNFT = useCallback(
    async (e) => {
      try {
        const buy = await contract.methods.rounds(e).call();
        return buy;
      } catch (error) {
        throw error;
      }
    },
    [contract, account, web3]
  );
  return { timerNFT: timerNFT };
};
export default TimerNFT;
