import React, { useState, useEffect } from "react";
import "./datatable.scss";
import { Accordion } from "react-bootstrap";
import moment from "moment";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { Api_Url } from "../../../utils/ApiUrl";
import { CopyToClipboard } from "react-copy-to-clipboard";

const RowListing = ({ index, item, usdPrice, user }) => {
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [nr, setNr] = useState(0);
  const [earn, setEarn] = useState(0);
  const [earnT, setEarnT] = useState(0);

  function convertToTitleCase(input) {
    if (input) {
      let words = input
        .split("_")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        );

      if (words[words.length - 1].toLowerCase() === "agent") {
        words[words.length - 1] = "Leader";
      }
      if (words[0] === "super" || words[0] === "Super") {
        return "Super";
      } else if (words[0] !== "agent") {
        return words.join(" ");
      } else {
        return "Leader";
      }
    } else {
      return "";
    }
  }

  const [copy, setCopy] = useState(false);
  const textCopiedFun2 = () => {
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 1000);
  };

  return (
    <>
      <tr className="destopviewss" key={index}>
        <td>
          <div className="twice-text">
            <h6>{moment(item?.transactionTime).utc().format("DD/MM/YYYY")}</h6>
          </div>
        </td>
        <td>
          {item?.transactionHash?.slice(0, 6)}...
          {item?.transactionHash?.slice(
            item?.transactionHash?.length - 3,
            item?.transactionHash?.length
          )}
          <a
            href={"https://etherscan.io/tx/" + item?.transactionHash}
            target="_blank"
          >
            <img src="\assets\link-icon.svg" alt="img" className="img-fluid" />
          </a>
        </td>
        {/* <td>Institutions</td> */}
        {/* {item?.user?.length > 0 ? <td>{item?.user[0]?.name}</td> : <td></td>} */}
        <td>
          {copy ? (
            <span className="adadasdasdasdasdasdsad">
              {item?.recipient?.slice(0, 6)}...
              {item?.recipient?.slice(
                item?.recipient?.length - 3,
                item?.recipient?.length
              )}{" "}
              <svg
                style={{ cursor: "pointer" }}
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                className=""
              >
                <path
                  d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          ) : (
            <>
              {item?.recipient && (
                <CopyToClipboard text={item?.by} onCopy={textCopiedFun2}>
                  <span className="adadasdasdasdasdasdsad">
                    {item?.recipient?.slice(0, 6)}...
                    {item?.recipient?.slice(
                      item?.recipient?.length - 3,
                      item?.recipient?.length
                    )}{" "}
                    <svg
                      style={{ cursor: "pointer" }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      className=""
                    >
                      <path
                        d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </CopyToClipboard>
              )}
            </>
          )}
        </td>
        {item?.email?.length > 0 ? (
          <td>{item?.email[0]?.recipientEmail}</td>
        ) : (
          <td>...</td>
        )}
        <td>
          {copy ? (
            <span className="adadasdasdasdasdasdsad">
              {item?.by?.slice(0, 6)}...
              {item?.by?.slice(item?.by?.length - 3, item?.by?.length)}{" "}
              <svg
                style={{ cursor: "pointer" }}
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                className=""
              >
                <path
                  d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          ) : (
            <>
              {item?.by && (
                <CopyToClipboard text={item?.by} onCopy={textCopiedFun2}>
                  <span className="adadasdasdasdasdasdsad">
                    {item?.by?.slice(0, 6)}...
                    {item?.by?.slice(
                      item?.by?.length - 3,
                      item?.by?.length
                    )}{" "}
                    <svg
                      style={{ cursor: "pointer" }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      className=""
                    >
                      <path
                        d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </CopyToClipboard>
              )}
            </>
          )}
        </td>
        <td>
          {item?.amountInvested + " " + item?.tokenSelected?.toLowerCase()}
        </td>
        <td>{(item?.dopPurchased).toFixed(2) + " DOP"}</td>
      </tr>
      <div className="new-accordionmobile d-none">
        <div className="upper-body">
          <div className="inner-text">
            <h6>Date and time</h6>
            <p> {moment(item?.transactionTime).utc().format("DD/MM/YYYY")}</p>
          </div>
          <div className="inner-text">
            <h6>Amount Spend</h6>
            {item?.tokenSelected === "ETH" ? (
              <p>
                {parseFloat(parseFloat(item?.amountInvested))
                  ?.toFixed(4)
                  .toLocaleString("en-US")}{" "}
                {item?.tokenSelected}
              </p>
            ) : (
              <p>
                {parseFloat(
                  parseFloat(item?.amountInvested)?.toFixed(2)
                ).toLocaleString("en-US")}{" "}
                {item?.tokenSelected}
              </p>
            )}
          </div>
        </div>
        <div className="bottom-body">
          <Accordion>
            <Accordion.Item eventKey={index}>
              <Accordion.Header>
                More Info{" "}
                <img
                  src="\assets\arrow-down.svg"
                  alt="img"
                  className="img-fluid"
                />
              </Accordion.Header>
              <Accordion.Body>
                <div className="inner-textt">
                  <p>Hash</p>
                  <h6>
                    {" "}
                    <a
                      href={"https://etherscan.io/tx/" + item?.transactionHash}
                      target="_blank"
                    >
                      Etherscan
                    </a>
                  </h6>
                </div>
                <div className="inner-textt">
                  <p>Wallet Address</p>
                  {copy ? (
                    <h6 className="adadasdasdasdasdasdsad">
                      {item?.by?.slice(0, 6)}...
                      {item?.by?.slice(
                        item?.by?.length - 3,
                        item?.by?.length
                      )}{" "}
                      <svg
                        style={{ cursor: "pointer" }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        className=""
                      >
                        <path
                          d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </h6>
                  ) : (
                    <>
                      {item?.by && (
                        <CopyToClipboard
                          text={item?.by}
                          onCopy={textCopiedFun2}
                        >
                          <h6 className="adadasdasdasdasdasdsad">
                            {item?.by?.slice(0, 6)}...
                            {item?.by?.slice(
                              item?.by?.length - 3,
                              item?.by?.length
                            )}{" "}
                            <svg
                              style={{ cursor: "pointer" }}
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              className=""
                            >
                              <path
                                d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </h6>
                        </CopyToClipboard>
                      )}
                    </>
                  )}
                </div>
                <div className="inner-textt">
                  <p>DOP Purchased</p>
                  <h6>{(item?.dopPurchased).toFixed(2) + " DOP"}</h6>
                </div>
                <div className="inner-textt">
                  <p>Amount Spent</p>
                  <div className="twice-text" style={{ textAlign: "right" }}>
                    <>
                      <h6>
                        {item?.amountInvested +
                          " " +
                          item?.tokenSelected?.toLowerCase()}
                      </h6>
                    </>

                    {item?.tokenSelected === "ETH" ? (
                      <p>
                        {item?.codeRole == "agent" ? (
                          <h6>
                            (
                            {(
                              parseFloat(item?.AgentAmount) * usdPrice
                            )?.toFixed(2)}{" "}
                            USD)
                          </h6>
                        ) : item?.codeRole == "god_agent" ? (
                          <h6>
                            (
                            {(
                              parseFloat(item?.GodAgentAmount) * usdPrice
                            )?.toFixed(2)}{" "}
                            USD)
                          </h6>
                        ) : item?.codeRole == "mega_agent" ? (
                          <h6>
                            (
                            {(
                              parseFloat(item?.MegaAgentAmount) * usdPrice
                            )?.toFixed(2)}{" "}
                            USD)
                          </h6>
                        ) : item?.codeRole == "super_agent" ? (
                          <h6>
                            (
                            {(
                              parseFloat(item?.SuperAgentAmount) * usdPrice
                            )?.toFixed(2)}{" "}
                            USD)
                          </h6>
                        ) : (
                          ""
                        )}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>{" "}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default RowListing;
