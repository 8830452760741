import React, { useState, useEffect } from "react";
import axios from "axios";
import DataTable from "./DataTable";
import DataTable2 from "./DataTable/DataTable";
import { useHistory } from "react-router-dom";

import ReactPaginate from "react-paginate";
import moment from "moment";
import { Api_Url } from "../../../utils/ApiUrl";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Modal } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";

const ViewDetail = ({
  setRoutes,
  user,
  userDel,
  setUserDel,
  DeleteUser,
  shouldUpdate,
  tomiPrice,
  usdPrice,
}) => {
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  // console.log("asdasdasdasdasdasdasdasdasdasd",userDel)
  const [transactions, setTransactions] = useState([]);
  const [purchases, setPurchases] = useState(null);
  const [leaderscount, setleaderscount] = useState(null);
  const [accessCodeToggle, setAccessCodeToggle] = useState(true);

  const [dops, setDops] = useState(null);
  const [search, setSearch] = useState("");
  let tok = localStorage.getItem("accessToken");

  const [dopPrice, setDopPrice] = useState(null);
  // const [usdPrice, setUsdPrice] = useState(0);
  const [page, setPage] = useState(0);
  const [earnings, setEarnings] = useState(null);

  const [agents, setAgents] = useState([]);

  const [offset, setOffset] = useState(1);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(1);
  const [pages, setPages] = useState([]);
  const [code, setCode] = useState([]);
  const [page1, setPage1] = useState(0);
  const [pageCount, setPageCount] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [leaderNames, setLeaderNames] = useState([]);
  const [leaderFilter, setLeaderFilter] = useState("");
  const [roleTypeFilter, setRoleTypeFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryNames, setSearchQueryNames] = useState("");

  const [orderField, setOrderField] = useState("transactionTime");
  const [orderDirection, setOrderDirection] = useState("-1");

  const [orderFieldAgents, setOrderFieldAgents] = useState("createdAt");
  const [orderDirectionAgents, setOrderDirectionAgents] = useState("-1");
  const [tabKey, setTabKey] = useState("transactions");

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  // const [tomiPrice, setTomiPrice] = useState("");

  // const getTomiPrice = async () => {
  //   try {
  //     const response = await axios.get(
  //       `https://api.coingecko.com/api/v3/simple/price`,
  //       {
  //         params: {
  //           ids: "tomiNet",
  //           vs_currencies: "usd",
  //         },
  //       }
  //     );

  //     const data = response?.data;
  //     const priceInUSD = data["tominet"]?.usd;

  //     setTomiPrice(priceInUSD);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //     // throw error;
  //   }
  // };
  useEffect(() => {
    // getTomiPrice();

    getLeaderNames();
  }, []);

  useEffect(() => {
    if (userDel) {
    } else {
      setUserDel(null);
      setRoutes(false);
    }
  }, [userDel]);

  // useEffect(() => {
  //   if (userDel) {
  //     getDashboardData();
  //   }
  // }, [userDel]);

  const getDashboardData = () => {
    var config = {
      method: "get",
      url: `${Api_Url}/users/${userDel?._id}/leaders?limit=15&offset=1&&role=${userDel?.role}`,

      headers: {
        Authorization: "Bearer " + tok,
      },
    };

    let dynamicQuery = config.url;

    if (search) {
      dynamicQuery += `&by=${search}`;
    }

    config.url = dynamicQuery;
    axios(config)
      .then(function (res) {
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
      });
  };

  const toggleAccessCode = async () => {
    var config = {
      method: "patch",
      url: `${Api_Url}/users/${userDel?._id}/toggle-access-code`,

      headers: {
        Authorization: "Bearer " + tok,
      },
    };

    await axios(config)
      .then(function (res) {
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
      });
  };

  useEffect(() => {
    if (user && tabKey == "leaders") {
      GetAgents();
    }
  }, [
    user,
    searchQuery,
    leaderFilter,
    roleTypeFilter,
    orderDirectionAgents,
    orderFieldAgents,
    tabKey,
  ]);

  const handlePageChange = (e) => {
    const selectedPage = e.selected;

    setPage1(selectedPage);
  };

  const getTransactionsData = () => {
    var config = {
      method: "get",
      url: `${Api_Url}/institutions/${
        userDel?._id
      }/all-transactions?limit=10&offset=${page1 + 1}`,

      headers: {
        Authorization: "Bearer " + tok,
      },
    };

    let dynamicQuery = config.url;

    if (search) {
      dynamicQuery += `&by=${search}`;
    }
    if (orderField) {
      dynamicQuery += `&orderField=${orderField}&orderDirection=${orderDirection}`;
    }
    config.url = dynamicQuery;
    axios(config)
      .then(function (res) {
        // console.log("trx data: ", res?.data?.data?.transactions);
        setTransactions(res?.data?.data?.transactions);
        setPageCount(res?.data?.data?.pages);
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
      });
  };

  const getLeaderNames = () => {
    const val = localStorage.getItem("accessToken");

    var config = {
      method: "get",
      url: `${Api_Url}/users/${userDel?._id}/leaders-names?limit=${500}&role=${
        userDel?.role
      }`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    let dynamicQuery = config.url;

    if (searchQueryNames) {
      dynamicQuery += `&name=${encodeURIComponent(searchQueryNames)}`;
    }
    axios(config)
      .then(function (response) {
        setLeaderNames(response?.data?.data);
      })
      .catch(function (error) {
        if (error?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          history.push("/");
        }
      });
  };

  const setSearchQuerying = (e) => {
    setSearchQuery(e);
    if (e === "") {
      GetAgents();
    }
  };

  const GetAgents = (off) => {
    off?.preventDefault();
    const val = localStorage.getItem("accessToken");
    let valu = null;
    if (typeof off !== "object" && off) {
      valu = off;
    } else {
      valu = 1;
    }
    var config = {
      method: "get",
      url: `${Api_Url}/users/${
        userDel?._id
      }/leaders?limit=${10}&&offset=${valu}&role=${userDel?.role}`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    let dynamicQuery = config.url;

    if (leaderFilter) {
      dynamicQuery += `&name=${encodeURIComponent(leaderFilter)}`;
    }

    if (roleTypeFilter) {
      dynamicQuery += `&leaderType=${encodeURIComponent(roleTypeFilter)}`;
    }
    if (searchQuery) {
      dynamicQuery += `&search=${encodeURIComponent(searchQuery)}`;
    }
    if (orderFieldAgents) {
      dynamicQuery += `&orderField=${orderFieldAgents}&orderDirection=${orderDirectionAgents}`;
    }
    config.url = dynamicQuery;

    axios(config)
      .then(function (response) {
        const resData = response?.data?.data;
        setCount(response.data.data.count);
        setAgents(resData?.agents);
        let arr = Array.from(Array(parseInt(response.data.data.pages)).keys());
        setPages(arr);
        setCurrentPage(valu);
        // setSearch('')
        if (off <= response.data.data.squad.length) {
          if (off - 1 == 0) {
            setLimit(1);
          } else {
            setLimit((off - 1) * 5);
          }
        }
        setLoader(false);
      })
      .catch(function (error) {
        if (error?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          history.push("/");
        }
        setLoader(false);
      });
  };

  const getPrevData = (off) => {
    let offset = parseInt(off) - 1;
    const val = localStorage.getItem("accessToken");
    if (offset > 0) {
      // setLoader(true);
      var config = null;
      config = {
        method: "get",
        url: `${Api_Url}/users/${userDel?._id}/leaders?offset=${offset}&&limit=10&&orderField=createdAt&&orderDirection=-1&role=${userDel?.role}`,
        headers: {
          Authorization: "Bearer " + val,
          "Content-Type": "application/json",
        },
      };

      axios(config)
        .then(function (response) {
          const resData = response?.data?.data;
          let arr = Array.from(
            Array(parseInt(response.data.data.pages)).keys()
          );
          setPages(arr);
          setAgents(resData?.agents);
          if (currentPage - 1 >= 0) {
            setCurrentPage(currentPage - 1);
          }
          if (off >= 0) {
            if (offset - 1 == 0) {
              setLimit(1);
            } else {
              setLimit((offset - 1) * 5);
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      // setLoader(false);
    }
  };

  const getNextData = (off) => {
    let offset = parseInt(off) + 1;
    const val = localStorage.getItem("accessToken");
    if (pages.length > off) {
      if (off < agents.length) {
        var config = null;
        config = {
          method: "get",
          url: `${Api_Url}/users/${userDel?._id}/leaders?offset=${offset}&&limit=10&&orderField=createdAt&&orderDirection=-1&role=${userDel?.role}`,
          headers: {
            Authorization: "Bearer " + val,
            "Content-Type": "application/json",
          },
        };

        axios(config)
          .then(function (response) {
            const resData = response?.data?.data;
            let arr = Array.from(
              Array(parseInt(response.data.data.pages)).keys()
            );
            setPages(arr);
            setAgents(resData?.agents);
            if (off <= agents.length) {
              setCurrentPage(offset);
              setLimit(off * 5);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
  };

  // useEffect(() => {
  //   axios
  //     .get("https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD")
  //     .then((res) => {
  //       setUsdPrice(res?.data?.USD);
  //       // setEthUsd(res.data);
  //     });
  // }, []);

  // console.log("items id", userDel)
  const getData = async () => {
    var config = {
      method: "get",
      url: `${Api_Url}/users/${userDel?._id}`,
      headers: {
        authorization: `Bearer ` + tok,
      },
    };
    axios(config)
      .then(function (response) {
        // console.log("user coount eth", response);
        const resData = response?.data?.data;
        setleaderscount(resData);
        setAccessCodeToggle(resData?.accessCodeEnabled);
      })
      .catch(function (error) {});
  };

  useEffect(() => {
    if (userDel) {
      getData();
    }
  }, [userDel]);

  function convertToTitleCase(input) {
    if (input) {
      let words = input
        .split("_")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        );

      if (words[words.length - 1].toLowerCase() === "agent") {
        words[words.length - 1] = "Leader";
      }
      if (words[0] === "super" || words[0] === "Super") {
        return "Super";
      } else if (words[0] !== "agent") {
        return words.join(" ");
      } else {
        return "Leader";
      }
    } else {
      return "";
    }
  }

  useEffect(() => {
    if (usdPrice && tabKey == "transactions") {
      getTransactionsData();
    }
  }, [usdPrice, userDel, search, orderDirection, orderField, tabKey, page1]);

  return (
    <>
      <section className="viewdetail">
        <div className="custom-container veiwdetaileinnerpage">
          <div className="main-heading">
            <button
              onClick={() => {
                setRoutes(false);
                setUserDel(null);
              }}
              className="btn-back"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
              >
                <path
                  d="M8.37376 16.4675C8.20751 16.4675 8.04126 16.4062 7.91001 16.275L2.59876 10.9637C2.34501 10.71 2.34501 10.29 2.59876 10.0362L7.91001 4.72498C8.16376 4.47123 8.58376 4.47123 8.83751 4.72498C9.09126 4.97873 9.09126 5.39873 8.83751 5.65248L3.99001 10.5L8.83751 15.3475C9.09126 15.6012 9.09126 16.0212 8.83751 16.275C8.71501 16.4062 8.54001 16.4675 8.37376 16.4675Z"
                  fill="white"
                />
                <path
                  d="M17.9375 11.1562H3.21124C2.85249 11.1562 2.55499 10.8587 2.55499 10.5C2.55499 10.1413 2.85249 9.84375 3.21124 9.84375H17.9375C18.2962 9.84375 18.5937 10.1413 18.5937 10.5C18.5937 10.8587 18.2962 11.1562 17.9375 11.1562Z"
                  fill="white"
                />
              </svg>{" "}
              Back
            </button>
            <div className="twice-btn">
              {user?.role == "admin" ? (
                <>
                  <div className="set-custom-claim-text">
                    <div className="parent-toggle-switch-custom">
                      <div class="custom-toggle">
                        <input
                          type="checkbox"
                          class="checkbox"
                          id="checkbox"
                          checked={!accessCodeToggle}
                          onChange={(e) => {
                            toggleAccessCode();
                            setAccessCodeToggle(!accessCodeToggle);
                          }}
                        />
                        <label class="switch" for="checkbox">
                          <span class="slider"></span>
                        </label>
                      </div>
                    </div>
                    <h6>Disable this Code</h6>
                  </div>
                  {/* <button onClick={setShow1} className="btn-edit">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                    >
                      <path
                        d="M17.5 18.8333H2.5C2.15833 18.8333 1.875 18.55 1.875 18.2083C1.875 17.8666 2.15833 17.5833 2.5 17.5833H17.5C17.8417 17.5833 18.125 17.8666 18.125 18.2083C18.125 18.55 17.8417 18.8333 17.5 18.8333Z"
                        fill="white"
                      />
                      <path
                        d="M15.85 3.39999C14.2333 1.78332 12.65 1.74166 10.9917 3.39999L9.98333 4.40832C9.9 4.49166 9.86666 4.62499 9.9 4.74166C10.5333 6.94999 12.3 8.71666 14.5083 9.34999C14.5417 9.35832 14.575 9.36666 14.6083 9.36666C14.7 9.36666 14.7833 9.33332 14.85 9.26666L15.85 8.25832C16.675 7.44166 17.075 6.64999 17.075 5.84999C17.0833 5.02499 16.6833 4.22499 15.85 3.39999Z"
                        fill="white"
                      />
                      <path
                        d="M13.0083 10.1084C12.7667 9.99169 12.5333 9.87502 12.3083 9.74169C12.125 9.63335 11.95 9.51669 11.775 9.39169C11.6333 9.30002 11.4667 9.16669 11.3083 9.03335C11.2917 9.02502 11.2333 8.97502 11.1667 8.90835C10.8917 8.67502 10.5833 8.37502 10.3083 8.04169C10.2833 8.02502 10.2417 7.96669 10.1833 7.89169C10.1 7.79169 9.95833 7.62502 9.83333 7.43335C9.73333 7.30835 9.61666 7.12502 9.50833 6.94169C9.375 6.71669 9.25833 6.49169 9.14166 6.25835C8.9887 5.93057 8.55849 5.8332 8.30271 6.08897L3.61666 10.775C3.50833 10.8834 3.40833 11.0917 3.38333 11.2334L2.93333 14.425C2.85 14.9917 3.00833 15.525 3.35833 15.8834C3.65833 16.175 4.075 16.3334 4.525 16.3334C4.625 16.3334 4.725 16.325 4.825 16.3084L8.025 15.8584C8.175 15.8334 8.38333 15.7334 8.48333 15.625L13.1771 10.9312C13.4278 10.6806 13.3336 10.2493 13.0083 10.1084Z"
                        fill="white"
                      />
                    </svg>
                    Edit
                  </button> */}
                </>
              ) : null}
              <button onClick={() => DeleteUser()} className="btn-del">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  <path
                    d="M17.5583 4.85835C16.2167 4.72502 14.875 4.62502 13.525 4.55002V4.54169L13.3417 3.45835C13.2167 2.69169 13.0333 1.54169 11.0833 1.54169H8.89999C6.95832 1.54169 6.77499 2.64169 6.64165 3.45002L6.46666 4.51669C5.69166 4.56669 4.91666 4.61669 4.14166 4.69169L2.44166 4.85835C2.09166 4.89169 1.84166 5.20002 1.87499 5.54169C1.90832 5.88335 2.20832 6.13335 2.55832 6.10002L4.25832 5.93335C8.62499 5.50002 13.025 5.66669 17.4417 6.10835C17.4667 6.10835 17.4833 6.10835 17.5083 6.10835C17.825 6.10835 18.1 5.86669 18.1333 5.54169C18.1583 5.20002 17.9083 4.89169 17.5583 4.85835Z"
                    fill="#FE0000"
                  />
                  <path
                    d="M16.025 7.28331C15.825 7.07498 15.55 6.95831 15.2667 6.95831H4.73335C4.45002 6.95831 4.16668 7.07498 3.97502 7.28331C3.78335 7.49165 3.67501 7.77498 3.69168 8.06665L4.20835 16.6166C4.30002 17.8833 4.41668 19.4666 7.32502 19.4666H12.675C15.5833 19.4666 15.7 17.8916 15.7917 16.6166L16.3083 8.07498C16.325 7.77498 16.2167 7.49165 16.025 7.28331ZM11.3833 15.2916H8.60835C8.26668 15.2916 7.98335 15.0083 7.98335 14.6666C7.98335 14.325 8.26668 14.0416 8.60835 14.0416H11.3833C11.725 14.0416 12.0083 14.325 12.0083 14.6666C12.0083 15.0083 11.725 15.2916 11.3833 15.2916ZM12.0833 11.9583H7.91668C7.57502 11.9583 7.29168 11.675 7.29168 11.3333C7.29168 10.9916 7.57502 10.7083 7.91668 10.7083H12.0833C12.425 10.7083 12.7083 10.9916 12.7083 11.3333C12.7083 11.675 12.425 11.9583 12.0833 11.9583Z"
                    fill="#FE0000"
                  />
                </svg>
                Delete
              </button>
            </div>
            {/* <div class="dropdown d-none">
                            <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <img src="\assets\more-icon.svg" alt="img" className="img-fluid" />
                            </button>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" href="#"><img src="\assets\edit.svg" alt="img" className="img-fluid" />Edit</a></li>
                                <li><a class="dropdown-item" href="#"><img src="\assets\delete.svg" alt="img" className="img-fluid" />Delete</a></li>
                            </ul>
                        </div> */}
          </div>
          <div className="inner-heading">
            <h4>
              {userDel?.name} - <span>{userDel?.accessCode}</span>
            </h4>
          </div>
          <div
            className={
              leaderscount?.role == "agent"
                ? "bottom-cards  newclassagent"
                : "bottom-cards "
            }
          >
            <div className="single-card">
              <img
                src="\assets\dashboard\noofpurchases.svg"
                alt="img"
                className="img-fluid"
              />
              <p>Number of Purchases</p>
              {userDel?.dopPurchased?.length > 0 ? (
                <h6>{userDel?.dopPurchased[0]?.totalPurchases}</h6>
              ) : (
                <h6>0</h6>
              )}
              {/* <div className="bottom-text">
                <p className="uni-text">
                  <span></span>
                  {leaderscount?.directSales
                    ? leaderscount?.directSales
                    : 0}{" "}
                  Direct
                </p>
                <p className="uni-text">
                  <span></span>
                  {leaderscount?.inDirectSales
                    ? leaderscount?.inDirectSales
                    : 0}{" "}
                  Indirect
                </p>
              </div> */}
            </div>
            <div className="single-card">
              <img
                src="\assets\dashboard\doppurchases.svg"
                alt="img"
                className="img-fluid"
              />
              <p>DOP Purchased</p>
              {userDel?.dopPurchased?.length > 0 ? (
                <h6>
                  {parseInt(
                    userDel?.dopPurchased[0]?.totalDopPurchased?.toFixed(2)
                  ).toLocaleString("en-US")}{" "}
                  &nbsp;DOP
                </h6>
              ) : (
                <h6>0.00 DOP</h6>
              )}
            </div>
            <div className="single-card">
              <img
                src="\assets\dashboard\purchasedamount.svg"
                alt="img"
                className="img-fluid"
              />
              <p>Purchased Amount</p>
              {tomiPrice && usdPrice && leaderscount ? (
                <>
                  <h6 className="datahead">
                    ~$
                    {parseFloat(
                      (
                        usdPrice *
                          (userDel.totalPurchases &&
                          userDel.totalPurchases?.length > 0 &&
                          userDel.totalPurchases[0]?._id == "ETH"
                            ? userDel.totalPurchases[0]?.purchasedAmount
                            : userDel.totalPurchases &&
                              userDel.totalPurchases?.length > 0 &&
                              userDel.totalPurchases[1]?._id == "ETH"
                            ? userDel.totalPurchases[1]?.purchasedAmount
                            : userDel.totalPurchases &&
                              userDel.totalPurchases?.length > 0 &&
                              userDel.totalPurchases[2]?._id == "ETH"
                            ? userDel.totalPurchases[2]?.purchasedAmount
                            : 0) +
                        (userDel.totalPurchases &&
                        userDel.totalPurchases?.length > 0 &&
                        userDel.totalPurchases[0]?._id == "USDT"
                          ? userDel.totalPurchases[0]?.purchasedAmount
                          : userDel.totalPurchases &&
                            userDel.totalPurchases?.length > 0 &&
                            userDel.totalPurchases[1]?._id == "USDT"
                          ? userDel.totalPurchases[1]?.purchasedAmount
                          : userDel.totalPurchases &&
                            userDel.totalPurchases?.length > 0 &&
                            userDel.totalPurchases[2]?._id == "USDT"
                          ? userDel.totalPurchases[2]?.purchasedAmount
                          : 0) +
                        tomiPrice *
                          (userDel.totalPurchases &&
                          userDel.totalPurchases?.length > 0 &&
                          userDel.totalPurchases[0]?._id == "TOMI"
                            ? userDel.totalPurchases[0]?.purchasedAmount
                            : userDel.totalPurchases &&
                              userDel.totalPurchases?.length > 0 &&
                              userDel.totalPurchases[1]?._id == "TOMI"
                            ? userDel.totalPurchases[1]?.purchasedAmount
                            : userDel.totalPurchases &&
                              userDel.totalPurchases?.length > 0 &&
                              userDel.totalPurchases[2]?._id == "TOMI"
                            ? userDel.totalPurchases[2]?.purchasedAmount
                            : 0)
                      )?.toFixed(4)
                    ).toLocaleString("en-US")}
                  </h6>
                </>
              ) : (
                <h6 className="datahead">~$0.00 USD</h6>
              )}
              <div className="bottom-text">
                <p className="uni-text">
                  <span></span>
                  {/* ? (user?.totalEth + user?.totalEthIndirect)?.toFixed(4) */}
                  {(userDel.totalPurchases &&
                  userDel.totalPurchases?.length > 0 &&
                  userDel.totalPurchases[0]?._id == "ETH"
                    ? userDel.totalPurchases[0]?.purchasedAmount
                    : userDel.totalPurchases &&
                      userDel.totalPurchases[1]?._id == "ETH"
                    ? userDel.totalPurchases[1]?.purchasedAmount
                    : userDel.totalPurchases &&
                      userDel.totalPurchases[2]?._id == "ETH"
                    ? userDel.totalPurchases[2]?.purchasedAmount
                    : 0
                  )?.toFixed(4)}{" "}
                  ETH
                </p>
                <p className="uni-text">
                  <span></span>
                  {userDel.totalPurchases &&
                  userDel.totalPurchases?.length > 0 &&
                  userDel.totalPurchases[0]?._id == "USDT"
                    ? userDel.totalPurchases[0]?.purchasedAmount
                    : userDel.totalPurchases &&
                      userDel.totalPurchases[1]?._id == "USDT"
                    ? userDel.totalPurchases[1]?.purchasedAmount
                    : userDel.totalPurchases &&
                      userDel.totalPurchases[2]?._id == "USDT"
                    ? userDel.totalPurchases[2]?.purchasedAmount
                    : 0?.toFixed(2)}{" "}
                  USDT
                </p>
                <p className="uni-text">
                  <span></span>
                  {userDel.totalPurchases &&
                  userDel.totalPurchases?.length > 0 &&
                  userDel.totalPurchases[0]?._id == "TOMI"
                    ? userDel.totalPurchases[0]?.purchasedAmount
                    : userDel.totalPurchases &&
                      userDel.totalPurchases[1]?._id == "TOMI"
                    ? userDel.totalPurchases[1]?.purchasedAmount
                    : userDel.totalPurchases &&
                      userDel.totalPurchases[2]?._id == "TOMI"
                    ? userDel.totalPurchases[2]?.purchasedAmount
                    : 0?.toFixed(2)}{" "}
                  TOMI
                </p>
              </div>
            </div>
          </div>
          <div className="parent-new-card">
            <div className="main-headingg">
              <h6>General Information</h6>
            </div>
            <div className="bottom-cards1">
              <div className="inner-text">
                <p>Access Code</p>
                <h6>{leaderscount?.accessCode}</h6>
              </div>
              <div className="inner-text">
                <p>Name</p>
                <h6>{leaderscount?.name}</h6>
              </div>
              <div className="inner-text">
                <p>DOP Price</p>
                <h6>{userDel?.dopPrice}</h6>
              </div>
              <div className="inner-text">
                <p>Created On</p>
                <h6>
                  {moment(leaderscount?.createdAt).format("DD/MM/YYYY hh:mm")}{" "}
                  UTC
                </h6>
              </div>
              <div className="inner-text">
                <p>Owner’s Wallet Address</p>
                <h6>
                  {userDel?.walletAddress?.slice(0, 11)}...
                  {userDel?.walletAddress?.slice(
                    userDel?.walletAddress?.length - 3,
                    userDel?.walletAddress?.length
                  )}
                </h6>
              </div>
              <div className="inner-text">
                <p>Email address</p>
                <h6>{userDel?.email}</h6>
              </div>
            </div>
          </div>
          <Tabs
            defaultActiveKey="transactions"
            id="uncontrolled-tab-example"
            className="viewdetail-tabs"
            onSelect={(e) => {
              setTabKey(e);
            }}
          >
            <Tab eventKey="transactions">
              <div className="main-heading111">
                <div
                  className={
                    userDel?.role !== "agent"
                      ? "rightside-content fxrxxrxxrexxr"
                      : "rightside-content "
                  }
                >
                  <div className=" custom-option-field w-100">
                    {/* <form onSubmit={(e) => GetSearch(e)}> */}
                    <input
                      onChange={(e) => setSearch(e.target.value)}
                      value={search}
                      type="search"
                      placeholder="Search..."
                    />
                    {/* </form> */}
                    <img
                      style={{ cursor: "pointer" }}
                      src="\assets\search-icon.svg"
                      alt="img"
                      className="img-fluid search-icon"
                      // onClick={() => GetSearch()}
                    />
                  </div>
                  {userDel?.role !== "agent" && (
                    <div class="dropdown">
                      {/* <button
                        class="dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Type{" "}
                        <img
                          src="\assets\arrow-down.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </button> */}
                      {userDel?.role === "god_agent" && (
                        <ul class="dropdown-menu">
                          <li>
                            <a
                              onClick={() => setRoleTypeFilter("agent")}
                              class="dropdown-item"
                              href="#"
                            >
                              Leader
                            </a>
                          </li>
                          <li>
                            <a
                              onClick={() => setRoleTypeFilter("god_agent")}
                              class="dropdown-item"
                              href="#"
                            >
                              God Leader
                            </a>
                          </li>
                          <li>
                            <a
                              onClick={() => setRoleTypeFilter("super_agent")}
                              class="dropdown-item"
                              href="#"
                            >
                              Super Leader
                            </a>
                          </li>
                          <li>
                            <a
                              onClick={() => setRoleTypeFilter("mega_agent")}
                              class="dropdown-item"
                              href="#"
                            >
                              Mega Leader
                            </a>
                          </li>
                        </ul>
                      )}
                      {userDel?.role === "mega_agent" && (
                        <ul class="dropdown-menu">
                          <li>
                            <a
                              onClick={() => setRoleTypeFilter("agent")}
                              class="dropdown-item"
                              href="#"
                            >
                              Leader
                            </a>
                          </li>
                          {/* <li>
                          <a
                            onClick={() => GetFilterRole("god_agent")}
                            class="dropdown-item"
                            href="#"
                          >
                            God Leader
                          </a>
                        </li> */}
                          <li>
                            <a
                              onClick={() => setRoleTypeFilter("super_agent")}
                              class="dropdown-item"
                              href="#"
                            >
                              Super Leader
                            </a>
                          </li>
                          <li>
                            <a
                              onClick={() => setRoleTypeFilter("mega_agent")}
                              class="dropdown-item"
                              href="#"
                            >
                              Mega Leader
                            </a>
                          </li>
                        </ul>
                      )}
                      {userDel?.role === "super_agent" && (
                        <ul class="dropdown-menu">
                          <li>
                            <a
                              onClick={() => setRoleTypeFilter("agent")}
                              class="dropdown-item"
                              href="#"
                            >
                              Leader
                            </a>
                          </li>
                          {/* <li>
                          <a
                            onClick={() => GetFilterRole("god_agent")}
                            class="dropdown-item"
                            href="#"
                          >
                            God Leader
                          </a>
                        </li> */}
                          <li>
                            <a
                              onClick={() => setRoleTypeFilter("super_agent")}
                              class="dropdown-item"
                              href="#"
                            >
                              Super Leader
                            </a>
                          </li>
                          {/* <li>
                          <a
                            onClick={() => GetFilterRole("mega_agent")}
                            class="dropdown-item"
                            href="#"
                          >
                            Mega Leader
                          </a>
                        </li> */}
                        </ul>
                      )}
                    </div>
                  )}
                  {/* <button className="reset-btn-global">Reset</button> */}
                </div>
              </div>
              {transactions?.length > 0 ? (
                <DataTable
                  transactions={transactions}
                  setTransactions={setTransactions}
                  user={user}
                  usdPrice={usdPrice}
                  page={page1}
                  pageCount={pageCount}
                  setOrderDirection={setOrderDirection}
                  setOrderField={setOrderField}
                  tomiPrice={tomiPrice}
                />
              ) : (
                <p
                  className=""
                  style={{ color: "#fff", textAlign: "center", marginTop: 100 }}
                >
                  No data found!
                </p>
              )}
              <div className="footer-content mt-2">
                <div className="left-f">
                  {/* <h6>SHOWING 1-10 OF 145</h6> */}
                  {/* <h6>SHOWING 1-10 OF 145</h6> */}
                </div>
                <div className="right-f">
                  <nav aria-label="Page navigation example">
                    <ul class="pagination">
                      <nav aria-label="Page navigation example">
                        <ul class="text-center">
                          <ReactPaginate
                            previousLabel={
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                                fill="none"
                              >
                                <path
                                  d="M10 13L5 8L10 3"
                                  stroke="#fff"
                                  stroke-width="1.5"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            }
                            nextLabel={
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                                fill="none"
                              >
                                <path
                                  d="M5 13L10 8L5 3"
                                  stroke="#fff"
                                  stroke-width="1.5"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            }
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageChange}
                            containerClassName="pagination"
                            activeClassName="active"
                            forcePage={page1}
                          ></ReactPaginate>
                        </ul>
                      </nav>
                    </ul>
                  </nav>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </section>

      <Modal
        className="add-mega-modal global-modal-scss"
        show={show1}
        onHide={handleClose1}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Special Access</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="multiselect-custom mt-5">
            <label className="custom-label">Special Access to</label>
            <Multiselect
              placeholder=""
              isObject={false}
              onKeyPressFn={function noRefCheck() {}}
              onRemove={function noRefCheck() {}}
              onSearch={function noRefCheck() {}}
              onSelect={function noRefCheck() {}}
              customCloseIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M5.14307 12.8571L12.8574 5.14285"
                    stroke="black"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12.8574 12.8571L5.14307 5.14285"
                    stroke="black"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              }
              options={[
                "Option 1",
                "Option 2",
                "Option 3",
                "Option 4",
                "Option 5",
              ]}
            />
          </div>
          <div className="twice-btn">
            <button
              onClick={() => {
                handleClose1();
              }}
              className="btn-cancel"
            >
              Back
            </button>
            <button disabled={loader} className="btn-add">
              {loader ? "Edit..." : "Edit"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewDetail;
