import React, { useState, useEffect } from "react";
import "./datatable.scss";
import { Accordion } from "react-bootstrap";
import moment from "moment";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { Api_Url } from "../../../utils/ApiUrl";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { parse } from "date-fns";

const RowListing = ({ index, item, usdPrice, user }) => {
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [nr, setNr] = useState(0);
  const [earn, setEarn] = useState(0);
  const [earnT, setEarnT] = useState(0);

  useEffect(() => {
    // calculate NR
    if (role !== "") {
      if (item?.tokenSelected === "ETH") {
        let vals =
          parseFloat(item?.AgentAmount) / 1000000000000000000 +
          parseFloat(item?.GodAgentAmount) / 1000000000000000000 +
          parseFloat(item?.MegaAgentAmount) / 1000000000000000000 +
          parseFloat(item?.SuperAgentAmount) / 1000000000000000000;
        let invest = parseFloat(item?.amountInvested) / 1000000000000000000;
        setEarnT(vals?.toFixed(4));
        setNr((invest - vals)?.toFixed(4));
        if (role === "admin") {
          let vals =
            parseFloat(item?.AgentAmount) / 1000000000000000000 +
            parseFloat(item?.GodAgentAmount) / 1000000000000000000 +
            parseFloat(item?.MegaAgentAmount) / 1000000000000000000 +
            parseFloat(item?.SuperAgentAmount) / 1000000000000000000;
          setEarn(vals?.toFixed(4));
        } else if (role === "agent") {
          let vals = parseFloat(item?.AgentAmount) / 1000000000000000000;
          setEarn(vals?.toFixed(4));
        } else if (role === "god_agent") {
          let vals = parseFloat(item?.GodAgentAmount) / 1000000000000000000;
          setEarn(vals?.toFixed(4));
        } else if (role === "mega_agent") {
          let vals = parseFloat(item?.MegaAgentAmount) / 1000000000000000000;
          setEarn(vals?.toFixed(4));
        } else if (role === "super_agent") {
          let vals = parseFloat(item?.SuperAgentAmount) / 1000000000000000000;
          setEarn(vals?.toFixed(4));
        }
      } else {
        let vals =
          parseFloat(item?.AgentAmount) / 1000000 +
          parseFloat(item?.GodAgentAmount) / 1000000 +
          parseFloat(item?.MegaAgentAmount) / 1000000 +
          parseFloat(item?.SuperAgentAmount) / 1000000;
        let invest = parseFloat(item?.amountInvested) / 1000000;
        setEarnT(vals?.toFixed(2));
        setNr((invest - vals)?.toFixed(2));
        if (role === "admin") {
          let vals =
            parseFloat(item?.AgentAmount) / 1000000 +
            parseFloat(item?.GodAgentAmount) / 1000000 +
            parseFloat(item?.MegaAgentAmount) / 1000000 +
            parseFloat(item?.SuperAgentAmount) / 1000000;
          setEarn(vals?.toFixed(4));
        } else if (role === "agent") {
          let vals = parseFloat(item?.AgentAmount) / 1000000;
          setEarn(vals?.toFixed(4));
        } else if (role === "god_agent") {
          let vals = parseFloat(item?.GodAgentAmount) / 1000000;
          setEarn(vals?.toFixed(4));
        } else if (role === "mega_agent") {
          let vals = parseFloat(item?.MegaAgentAmount) / 1000000;
          setEarn(vals?.toFixed(4));
        } else if (role === "super_agent") {
          let vals = parseFloat(item?.SuperAgentAmount) / 1000000;
          setEarn(vals?.toFixed(4));
        }
      }
    }
  }, [role, item]);

  useEffect(() => {
    let dumArr = [item?.code_code];
    // console.log("code veiw detail side", dumArr);

    const val = localStorage.getItem("accessToken");
    // const url = `${Api_Url}/users/names?accessCodes[]=${dumArr.join(
    //   "&accessCodes[]="
    // )}`;
    // var config = {
    //   method: "get",
    //   url: url,
    //   headers: {
    //     Authorization: "Bearer " + val,
    //   },
    // };

    // axios(config)
    //   .then(function (response) {
    //     if (response?.data?.data?.length > 0) {
    //       let obj = response?.data?.data[0];
    //       setName(obj?.name);
    //       setRole(obj?.role);
    //       // console.log("rrrrrr", obj)
    //     }
    //   })
    //   .catch(function (error) {});
  }, [item, user]);

  function convertToTitleCase(input) {
    if (input) {
      let words = input
        .split("_")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        );

      if (words[0] === "mini" || words[0] === "Mini") {
        return "Ambassador";
      }
      if (
        words[words.length - 1].toLowerCase() === "agent" &&
        (words[0] != "mini" || words[0] != "Mini")
      ) {
        words[words.length - 1] = "Leader";
      }
      if (words[0] === "super" || words[0] === "Super") {
        return "Super";
      } else if (words[0] !== "agent") {
        return words.join(" ");
      } else {
        return "Leader";
      }
    } else {
      return "";
    }
  }

  const [copy, setCopy] = useState(false);
  const textCopiedFun2 = () => {
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 1000);
  };

  return (
    <>
      <tr className="destopviewss" key={index}>
        <td>
          <div className="twice-text">
            <h6>{moment(item?.transactionTime).utc().format("DD/MM/YYYY")}</h6>
            <p>
              {" "}
              {moment(item?.transactionTime)
                .utc()
                .utcOffset("+02:00")
                .format("hh:mm A")}
            </p>
          </div>
        </td>
        <td>
          {item?.transactionHash?.slice(0, 6)}...
          {item?.transactionHash?.slice(
            item?.transactionHash?.length - 3,
            item?.transactionHash?.length
          )}
          <a
            href={"https://etherscan.io/tx/" + item?.transactionHash}
            target="_blank"
          >
            <img src="\assets\link-icon.svg" alt="img" className="img-fluid" />
          </a>
        </td>
        <td>
          <div className="twice-text">
            <h6>{item?.user[0]?.name}</h6>
            <p>
              {role === user?.role ? (
                <td>Direct</td>
              ) : (
                <>{convertToTitleCase(item?.codeRole)}</>
              )}
            </p>
          </div>
        </td>
        {user?.role == "admin" ? (
          <td>{item?.email[0]?.recipientEmail}</td>
        ) : null}

        <td>
          {copy ? (
            <span className="adadasdasdasdasdasdsad">
              {item?.by?.slice(0, 6)}...
              {item?.by?.slice(item?.by?.length - 3, item?.by?.length)}{" "}
              <svg
                style={{ cursor: "pointer" }}
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                className=""
              >
                <path
                  d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          ) : (
            <>
              {item?.by && (
                <CopyToClipboard text={item?.by} onCopy={textCopiedFun2}>
                  <span className="adadasdasdasdasdasdsad">
                    {item?.by?.slice(0, 6)}...
                    {item?.by?.slice(
                      item?.by?.length - 3,
                      item?.by?.length
                    )}{" "}
                    <svg
                      style={{ cursor: "pointer" }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      className=""
                    >
                      <path
                        d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </CopyToClipboard>
              )}
            </>
          )}

          {/* <img
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigator.clipboard.writeText(item?.by);
            }}
            src="\assets\copy.svg"
            alt="img"
            className="img-fluid"
          /> */}
        </td>
        {item?.tokenSelected === "ETH" ? (
          <td>
            {parseFloat(parseFloat(item?.amountInvested))
              ?.toFixed(4)
              .toLocaleString("en-US")}{" "}
            {item?.tokenSelected}
          </td>
        ) : (
          <td>
            {parseFloat(
              parseFloat(item?.amountInvested)?.toFixed(2)
            ).toLocaleString("en-US")}{" "}
            {item?.tokenSelected}
          </td>
        )}
        <td>{(item?.dopPurchased).toFixed(2) + " DOP"}</td>
        <td>{item?.type}</td>

        <td>
          <div className="twice-text">
            {item?.codeRole == "agent" ? (
              <h6>
                {parseFloat(item?.AgentAmount).toFixed(4)} {item?.tokenSelected}
              </h6>
            ) : item?.codeRole == "god_agent" ? (
              <h6>
                {parseFloat(item?.GodAgentAmount).toFixed(4)}{" "}
                {item?.tokenSelected}
              </h6>
            ) : item?.codeRole == "mega_agent" ? (
              <h6>
                {parseFloat(item?.MegaAgentAmount).toFixed(4)}{" "}
                {item?.tokenSelected}
              </h6>
            ) : item?.codeRole == "super_agent" ? (
              <h6>
                {parseFloat(item?.SuperAgentAmount).toFixed(4)}{" "}
                {item?.tokenSelected}
              </h6>
            ) : item?.codeRole == "mini_agent" ? (
              <h6>
                {parseFloat(item?.MiniAgentAmount).toFixed(4)}{" "}
                {item?.tokenSelected}
              </h6>
            ) : (
              ""
            )}
            {item?.tokenSelected === "ETH" ? (
              <p>
                {item?.codeRole == "agent" ? (
                  <h6>
                    ({(parseFloat(item?.AgentAmount) * usdPrice)?.toFixed(2)}{" "}
                    USD)
                  </h6>
                ) : item?.codeRole == "god_agent" ? (
                  <h6>
                    ({(parseFloat(item?.GodAgentAmount) * usdPrice)?.toFixed(2)}{" "}
                    USD)
                  </h6>
                ) : item?.codeRole == "mega_agent" ? (
                  <h6>
                    (
                    {(parseFloat(item?.MegaAgentAmount) * usdPrice)?.toFixed(2)}{" "}
                    USD)
                  </h6>
                ) : item?.codeRole == "super_agent" ? (
                  <h6>
                    (
                    {(parseFloat(item?.SuperAgentAmount) * usdPrice)?.toFixed(
                      2
                    )}{" "}
                    USD)
                  </h6>
                ) : item?.codeRole == "mini_agent" ? (
                  <h6>
                    (
                    {(parseFloat(item?.MiniAgentAmount) * usdPrice)?.toFixed(2)}{" "}
                    USD)
                  </h6>
                ) : (
                  ""
                )}
              </p>
            ) : (
              ""
            )}
          </div>
        </td>
        {user?.role == "admin" ? (
          <td>
            <div className="twice-text">
              {item?.tokenSelected === "ETH" ? (
                <h6>
                  {(item?.amountInvested - item?.myEarningsEth).toFixed(6)}{" "}
                  {item?.tokenSelected}
                </h6>
              ) : item?.tokenSelected === "TOMI" ? (
                <h6>
                  {(item?.amountInvested - item?.myEarningsTomi).toFixed(4)}{" "}
                  {item?.tokenSelected}
                </h6>
              ) : (
                <h6>
                  {parseFloat(
                    item?.amountInvested - item?.myEarningsUsdt
                  )?.toLocaleString("en-US")}{" "}
                  {item?.tokenSelected}
                </h6>
              )}
              {item?.tokenSelected === "ETH" ? (
                <p>
                  (
                  {(
                    parseFloat(item?.amountInvested - item?.myEarningsEth) *
                    usdPrice
                  )?.toFixed(4)}{" "}
                  USD)
                </p>
              ) : (
                ""
              )}
            </div>
          </td>
        ) : (
          ""
        )}
      </tr>
      <div className="new-accordionmobile d-none">
        <div className="upper-body">
          <div className="inner-text">
            <h6>Date and time</h6>
            <p> {moment(item?.transactionTime).utc().format("DD/MM/YYYY")}</p>

            <p>
              {" "}
              {moment(item?.transactionTime)
                .utc()
                .utcOffset("+02:00")
                .format("hh:mm A")}
            </p>
          </div>
          <div className="inner-text">
            <h6>Leader</h6>
            <p>{item?.user[0]?.name}</p>
          </div>
          <div className="inner-text">
            <h6>Sale Type</h6>
            {user?.role === role ? (
              <p>Direct</p>
            ) : (
              <p>{convertToTitleCase(role)}</p>
            )}
          </div>
          <div className="inner-text">
            <h6>Amount Spend</h6>
            {item?.tokenSelected === "ETH" ? (
              <p>
                {parseFloat(parseFloat(item?.amountInvested))
                  ?.toFixed(4)
                  .toLocaleString("en-US")}{" "}
                {item?.tokenSelected}
              </p>
            ) : (
              <p>
                {parseFloat(
                  parseFloat(item?.amountInvested)?.toFixed(2)
                ).toLocaleString("en-US")}{" "}
                {item?.tokenSelected}
              </p>
            )}
          </div>
        </div>
        <div className="bottom-body">
          <Accordion>
            <Accordion.Item eventKey={index}>
              <Accordion.Header>
                More Info{" "}
                <img
                  src="\assets\arrow-down.svg"
                  alt="img"
                  className="img-fluid"
                />
              </Accordion.Header>
              <Accordion.Body>
                <div className="inner-textt">
                  <p>Hash</p>
                  <h6>
                    {" "}
                    <a
                      href={"https://etherscan.io/tx/" + item?.transactionHash}
                      target="_blank"
                    >
                      Etherscan
                    </a>
                  </h6>
                </div>
                <div className="inner-textt">
                  <p>Wallet Address</p>
                  {copy ? (
                    <h6 className="adadasdasdasdasdasdsad">
                      {item?.by?.slice(0, 6)}...
                      {item?.by?.slice(
                        item?.by?.length - 3,
                        item?.by?.length
                      )}{" "}
                      <svg
                        style={{ cursor: "pointer" }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        className=""
                      >
                        <path
                          d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </h6>
                  ) : (
                    <>
                      {item?.by && (
                        <CopyToClipboard
                          text={item?.by}
                          onCopy={textCopiedFun2}
                        >
                          <h6 className="adadasdasdasdasdasdsad">
                            {item?.by?.slice(0, 6)}...
                            {item?.by?.slice(
                              item?.by?.length - 3,
                              item?.by?.length
                            )}{" "}
                            <svg
                              style={{ cursor: "pointer" }}
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              className=""
                            >
                              <path
                                d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </h6>
                        </CopyToClipboard>
                      )}
                    </>
                  )}
                </div>
                <div className="inner-textt">
                  <p>DOP Purchased</p>
                  <h6>{(item?.dopPurchased).toFixed(2) + " DOP"}</h6>
                </div>

                <div className="inner-textt">
                  <p>Type</p>
                  <h6>{item?.type}</h6>
                </div>

                <div className="inner-textt">
                  <p>Leader Earnings</p>
                  <div className="twice-text" style={{ textAlign: "right" }}>
                    <>
                      {item?.codeRole == "agent" ? (
                        <h6>
                          {parseFloat(item?.AgentAmount).toFixed(4)}{" "}
                          {item?.tokenSelected}
                        </h6>
                      ) : item?.codeRole == "god_agent" ? (
                        <h6>
                          {parseFloat(item?.GodAgentAmount).toFixed(4)}{" "}
                          {item?.tokenSelected}
                        </h6>
                      ) : item?.codeRole == "mega_agent" ? (
                        <h6>
                          {parseFloat(item?.MegaAgentAmount).toFixed(4)}{" "}
                          {item?.tokenSelected}
                        </h6>
                      ) : item?.codeRole == "super_agent" ? (
                        <h6>
                          {parseFloat(item?.SuperAgentAmount).toFixed(4)}{" "}
                          {item?.tokenSelected}
                        </h6>
                      ) : item?.codeRole == "mini_agent" ? (
                        <h6>
                          {parseFloat(item?.MiniAgentAmount).toFixed(4)}{" "}
                          {item?.tokenSelected}
                        </h6>
                      ) : (
                        ""
                      )}
                    </>

                    {item?.tokenSelected === "ETH" ? (
                      <p>
                        {item?.codeRole == "agent" ? (
                          <h6>
                            (
                            {(
                              parseFloat(item?.AgentAmount) * usdPrice
                            )?.toFixed(2)}{" "}
                            USD)
                          </h6>
                        ) : item?.codeRole == "god_agent" ? (
                          <h6>
                            (
                            {(
                              parseFloat(item?.GodAgentAmount) * usdPrice
                            )?.toFixed(2)}{" "}
                            USD)
                          </h6>
                        ) : item?.codeRole == "mega_agent" ? (
                          <h6>
                            (
                            {(
                              parseFloat(item?.MegaAgentAmount) * usdPrice
                            )?.toFixed(2)}{" "}
                            USD)
                          </h6>
                        ) : item?.codeRole == "super_agent" ? (
                          <h6>
                            (
                            {(
                              parseFloat(item?.SuperAgentAmount) * usdPrice
                            )?.toFixed(2)}{" "}
                            USD)
                          </h6>
                        ) : item?.codeRole == "mini_agent" ? (
                          <h6>
                            (
                            {(
                              parseFloat(item?.MiniAgentAmount) * usdPrice
                            )?.toFixed(2)}{" "}
                            USD)
                          </h6>
                        ) : (
                          ""
                        )}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {user?.role == "admin" ? (
                  <div className="inner-textt">
                    <p>Net Revenue</p>
                    <div className="twice-text" style={{ textAlign: "right" }}>
                      {item?.tokenSelected === "ETH" ? (
                        <h6>
                          {(item?.amountInvested - item?.myEarningsEth).toFixed(
                            6
                          )}{" "}
                          {item?.tokenSelected}
                        </h6>
                      ) : item?.tokenSelected === "TOMI" ? (
                        <h6>
                          {(
                            item?.amountInvested - item?.myEarningsTomi
                          ).toFixed(4)}{" "}
                          {item?.tokenSelected}
                        </h6>
                      ) : (
                        <h6>
                          {parseFloat(
                            item?.amountInvested - item?.myEarningsUsdt
                          )?.toLocaleString("en-US")}{" "}
                          {item?.tokenSelected}
                        </h6>
                      )}
                      {item?.tokenSelected === "ETH" ? (
                        <p>
                          (
                          {(
                            parseFloat(
                              item?.amountInvested - item?.myEarningsEth
                            ) * usdPrice
                          )?.toFixed(4)}{" "}
                          USD)
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
      {/* <Accordion.Item className="mobileviewnew" eventKey={index}>
        <Accordion.Header>
          <div className="accheadermain">
            <p className="acctext">
            {moment(item?.blockTimestamp * 1000).utc().format("DD/MM/YYYY")}
            </p>
            <a
              href={"https://etherscan.io/tx/" + item?.transactionHash}
              target="_blank"
            >
              <p className="acctext">
                <img
                  src="\assets\link-icon.svg"
                  alt="img"
                  className="img-fluid"
                />{" "}
              </p>
            </a>
            {role === user?.role ? (
              <p className="acctext">Direct</p>
            ) : (
              <p className="acctext">{role}</p>
            )}
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <div className="acctexts">
            <h6 className="textleft">Leader</h6>
            <p className="textright">{name}</p>
          </div>
          <div className="acctexts">
            <h6 className="textleft">Wallet Address</h6>
            <p className="textright">
              {item?.by?.slice(0, 6)}...
              {item?.by?.slice(item?.by?.length - 3, item?.by?.length)}{" "}
              <img src="\assets\copy.svg" alt="img" className="img-fluid" />
            </p>
          </div>
          <div className="acctexts">
            <h6 className="textleft">Amount Spent</h6>
            {item?.tokenSelected === "ETH" ? (
              <p className="textright">
                {parseInt(parseInt(item?.amountInvested) / 1000000000000000000)
                  ?.toFixed(4)
                  .toLocaleString("en-US")}{" "}
                {item?.tokenSelected}
              </p>
            ) : (
              <p className="textright">
                {parseInt(
                  (parseInt(item?.amountInvested) / 1000000)?.toFixed(2)
                ).toLocaleString("en-US")}{" "}
                {item?.tokenSelected}
              </p>
            )}
          </div>
          <div className="acctexts">
            <h6 className="textleft">DOP Purchased</h6>
            {item?.tokenSelected === "ETH" ? (
              <p className="textright">
                {parseFloat(
                  (usdPrice *
                    (parseInt(item?.amountInvested) / 1000000000000000000)) /
                    (parseInt(item?.price) / 1000000000000000000)
                )?.toLocaleString("en-US")}{" "}
                DOP
              </p>
            ) : (
              <p className="textright">
                {parseFloat(
                  (
                    parseInt(item?.amountInvested) /
                    1000000 /
                    (parseInt(item?.price) / 1000000000000000000)
                  )?.toFixed(2)
                )?.toLocaleString("en-US")}{" "}
                DOP
              </p>
            )}
          </div>
          <div className="acctexts">
            <h6 className="textleft">Earnings</h6>
            <div className="twice-text">
              <h6>${earn}</h6>
              {item?.tokenSelected === "ETH" ? (
                <p>({earn * usdPrice} USD)</p>
              ) : (
                ""
              )}
            </div>
          </div>
          {user?.role == "admin" ? (
            <div className="acctexts">
              <h6 className="textleft">Net Revenue</h6>
              <div className="twice-text">
                <h6>
                  {parseFloat(nr)?.toLocaleString("en-US")}{" "}
                  {item?.tokenSelected}
                </h6>
                {item?.tokenSelected === "ETH" ? (
                  <p>({(parseFloat(nr) * usdPrice)?.toFixed(4)} USD)</p>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            ""
          )}
        </Accordion.Body>
      </Accordion.Item> */}
    </>
  );
};

export default RowListing;
