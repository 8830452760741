import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import {
  getclaimscontract,
  getclaimsnewcontract,
} from "../../utils/contractHelpers";
import { getClaimNewLatest } from "../../utils/contractHelpers";
export const Claimable = () => {
  const web3 = useWeb3();
  const tokenAddress = environment.Claims;
  const contract = getclaimscontract(tokenAddress, web3);
  const tokenAddressNew = environment.ClaimsNew;
  const contractNew = getclaimsnewcontract(tokenAddressNew, web3);
  const tokenAddressTomi = environment.claimNewLatest;
  const contractNewLatest = getClaimNewLatest(tokenAddressTomi, web3);
  const CheckClaimableHook = useCallback(
    async (walletAddress, round, token) => {
      if (parseInt(round) < 3) {
        try {
          const claimAmount = await contract.methods
            .toClaim(walletAddress, round)
            .call();
          return claimAmount;
        } catch (error) {
          throw error;
        }
      } else if (parseInt(round) > 6) {
        try {
          const claimAmount = await contractNewLatest.methods
            .pendingClaims(walletAddress, round, token)
            .call();
          return claimAmount;
        } catch (error) {
          throw error;
        }
      } else {
        try {
          const claimAmount = await contractNew.methods
            .toClaim(walletAddress, round)
            .call();
          return claimAmount;
        } catch (error) {
          throw error;
        }
      }
    },
    [contract]
  );

  return { CheckClaimableHook: CheckClaimableHook };
};

export default Claimable;
