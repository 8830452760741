let Environment = {
  TomiContract: "0x4385328cc4D643Ca98DfEA734360C0F596C83449",
  // CreateDopRound: "0x6cE532764F3c7700cdA0967a6aC5B4f636201491", // main net contract
  CreateDopRound: "0x2D4e8121AeE68679054018037832947a3E0Bcf8A",
  // CreateNFTRound: "0x5C5576b7e36270A78A35495B5843Fc1EBb4b5FfE", //test net

  CreateNFTRound: "0x1119c2d708E42Fb591FCe77283b0791a135855ba", //main net

  CreateDopTomiRound: "0xf4Fa4f08811Ea1F79a809129192279d97D88EF51", //test net
  // CreateNFTRound: "0x5C5576b7e36270A78A35495B5843Fc1EBb4b5FfE", //test net

  CreateNFTTomiRound: "0xCFd7cB1528f32aDFFC40D2A7199E80799E4E4736", //test net

  DOPRoundSingleContract: "0x2B316b6E4fFD1984a2dE9b33E42787923d77F390", // main net

  InstitutionPreSale: "0x3e3Fbf2E10f3f3399c74180803e6721eC20d5c65", // main net

  Claims: "0x08669B18fe3A661400ac8dddF837e90861BFf5B6", //this one is for rounds 1 and 2
  ClaimsNew: "0x1B7168cA64fCd77fDF713767e6cDe2d7757241AB", // rounds greater then 2
  oldGraph:
    "https://api.studio.thegraph.com/query/51954/dopgraphcombined/version/latest", // for round smaller than or equal to 6
  graph: "https://api.thegraph.com/subgraphs/name/saifdevblochain/presaledop", // for round greater then 6

  institutionGraph:
    "https://api.thegraph.com/subgraphs/name/saifdevblochain/presaledopinstitution",

  claimNewLatest: "0x2cb197409ae65b344a611E2ab99A0E864EF28d4c",
  busd: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
  tomi: "0x4385328cc4D643Ca98DfEA734360C0F596C83449",
  eth: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
  usdc: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
  wbtc: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
};
export default Environment;
