import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { useWeb3React } from "@web3-react/core";
import { getClaimNewLatest } from "../../utils/contractHelpers";

const BuyAgain1 = () => {
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const tokenAddress = environment.claimNewLatest;
  const contract = getClaimNewLatest(tokenAddress, web3);
  const buyAgain1 = useCallback(
    async (round, min, api) => {
      console.log(
        api?.round,
        api?.deadline,
        api?.factors,
        api?.prices,
        api?.tokens,
        api?.amounts,
        min,
        api.v,
        api.r,
        api.s,
        "api?.round,api?.deadline,api?.factors,api?.prices,api?.tokens,api?.amounts,min,api.v,api.r,api.s"
      );
      //   let nd1 = 0;
      //   let nd2 = 0;
      //   if (amount21 > 0) {
      //     amount21 = parseFloat(amount21).toFixed(6);
      //     let amount1 = Math.round(amount21 * 1e12) / 1e12;
      //     nd1 = web3.utils.toWei(amount1.toString(), "mwei");
      //   } else {
      //   }
      //   if (amount > 0) {
      //     amount = parseFloat(amount).toFixed(6);
      //     let amount111 = Math.round(amount * 1e12) / 1e12;
      //     nd2 = web3.utils.toWei(amount111.toString(), "ether");
      //   } else {
      //   }
      try {
        let gasPrice = await web3.eth.getGasPrice();
        gasPrice = parseInt(gasPrice) + 3000000000;
        const gas = await contract.methods
          .purchaseWithClaim(
            api?.round,
            api?.deadline,
            api?.factors,
            api?.prices,
            api?.tokens,
            api?.amounts,
            min,
            api.v,
            api.r,
            api.s
          )
          .estimateGas({
            from: account,
          });
        const details = await contract.methods
          .purchaseWithClaim(
            api?.round,
            api?.deadline,
            api?.factors,
            api?.prices,
            api?.tokens,
            api?.amounts,
            min,
            api.v,
            api.r,
            api.s
          )
          .send({
            from: account,
            gas: gas,
            gasPrice,
          });
        return details;
      } catch (error) {
        throw error;
      }
    },
    [contract, account, web3]
  );
  return { buyAgain1: buyAgain1 };
};
export default BuyAgain1;
