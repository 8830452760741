import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import tableup from "../../../../assets/tableup.svg";
import tabledown from "../../../../assets/tabledown.svg";
import { Accordion, Modal } from "react-bootstrap";

import Loader from "../../../../hooks/loader";
import TableRow from "./TableRow";
import TableRowMobile from "./TableRowMobile";

const DataTable = ({
  tomiPrice,
  usdPrice,
  btcPrice,
  claimsData,
  rend,
  setRend,
}) => {
  const [loaderr, setLoarder] = useState(false);
  // // console.log('tererererer', claimsData)

  return (
    <>
      {loaderr && <Loader />}
      <section className="datatable">
        <div className="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>
                  <div className="parent-tag">
                    Round{" "}
                    <div className="filter-btn">
                      {/* <img src={tableup} alt="img" className='img-fluid' />
                                            <img src={tabledown} alt="img" className='img-fluid' /> */}
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Amount{" "}
                    <div className="filter-btn">
                      {/* <img src={tableup} alt="img" className='img-fluid' />
                                            <img src={tabledown} alt="img" className='img-fluid' /> */}
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Status{" "}
                    <div className="filter-btn">
                      {/* <img src={tableup} alt="img" className='img-fluid' />
                                            <img src={tabledown} alt="img" className='img-fluid' /> */}
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Claim{" "}
                    <div className="filter-btn">
                      {/* <img src={tableup} alt="img" className='img-fluid' />
                                            <img src={tabledown} alt="img" className='img-fluid' /> */}
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {/* claimsData */}
              {claimsData?.map((item) => {
                return (
                  item?.length > 0 && (
                    <TableRow
                      item={item}
                      usdPrice={usdPrice}
                      tomiPrice={tomiPrice}
                      btcPrice={btcPrice}
                      setLoarder={setLoarder}
                      loaderr={loaderr}
                      setRend={setRend}
                      rend={rend}
                    />
                  )
                );
              })}
            </tbody>
          </table>
          {/* <div className="footer-content">
                        <div className="left-f">
                            <h6>SHOWING 1-10 OF 145</h6>
                        </div>
                        <div className="right-f">
                            <nav aria-label="Page navigation example">
                                <ul class="pagination">
                                    <li class="page-item"><a class="page-link" href="#">{"<"}</a></li>
                                    <li class="page-item"><a class="page-link active" href="#">1</a></li>
                                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                                    <li class="page-item"><a class="page-link" href="#">4</a></li>
                                    <li class="page-item"><a class="page-link" href="#">5</a></li>
                                    <li class="page-item"><a class="page-link" href="#">{">"}</a></li>
                                </ul>
                            </nav>
                        </div>
                    </div> */}
        </div>
        <div className="mobileview-claim d-none">
          <div className="claim-mobile-view">
            {claimsData?.map((item) => {
              return (
                item?.length > 0 && (
                  <TableRowMobile
                    item={item}
                    usdPrice={usdPrice}
                    tomiPrice={tomiPrice}
                    btcPrice={btcPrice}
                    setLoarder={setLoarder}
                    loaderr={loaderr}
                    setRend={setRend}
                    rend={rend}
                  />
                )
              );
            })}
          </div>
          {/* <div className="footer-content">
                        <div className="left-f">
                        </div>
                        <div className="right-f">
                            <nav aria-label="Page navigation example">
                                <ul class="pagination">
                                    <li class="page-item"><a class="page-link" href="#">{"<"}</a></li>
                                    <li class="page-item"><a class="page-link active" href="#">1</a></li>
                                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                                    <li class="page-item"><a class="page-link" href="#">4</a></li>
                                    <li class="page-item"><a class="page-link" href="#">5</a></li>
                                    <li class="page-item"><a class="page-link" href="#">{">"}</a></li>
                                </ul>
                            </nav>
                        </div>
                    </div> */}
        </div>
      </section>
    </>
  );
};

export default DataTable;
